import { BarChartOutlined, RetweetOutlined } from '@ant-design/icons';
import { COIN } from '@bcpros/lixi-models/constants/coins/coin';
import { coinInfo } from '@bcpros/lixi-models/constants/coins/coin-info';
import { BurnForType } from '@bcpros/lixi-models/lib/burn/burn.model';
import { WalletContext } from '@context/walletProvider';
import { PostQueryItem } from '@generated/types';
import { Coin, RepostInput } from '@generated/types.generated';
import useCoin from '@hooks/useCoin';
import { getSelectedAccount } from '@store/account';
import { useSliceDispatch, useSliceSelector } from '@store/index';
import { useRepostMutation } from '@store/post/posts.api';
import { showToast } from '@store/toast/actions';
import { getAllWalletPaths, getSlpBalancesAndUtxos } from '@store/wallet';
import { getUtxoWif } from '@utils/cashMethods';
import { Space, Tooltip } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { formatBalance } from 'src/utils/cashMethods';
import styled from 'styled-components';
import { WithAuthorizeAction } from './Authorization/WithAuthorizeAction';
import Counter from './Counter';
import BaseReaction from './Reaction';
import ShareSocialButton from './ShareSocialButton';
import _ from 'lodash';
import { ShareForType } from '@bcpros/lixi-models/constants/share';
import { useConvertDanaToCoinQuery } from '@store/dana/dana.api';

export const GroupIconText = styled.div`
  align-items: center;
  display: flex;
  .ant-space {
    cursor: pointer;
    margin-right: 1rem;
    align-items: end;
    border-radius: 12px;
    cursor: pointer;
    @media (max-width: 960px) {
      margin-right: 0;
    }

    &.repost,
    &.dana-view-score {
      svg {
        color: var(--color-primary);
        width: 28px;
        height: 28px;
      }
    }
  }
  img {
    width: 28px;
    height: 28px;
  }
  .count {
    color: rgba(30, 26, 29, 0.6);
    font-size: 12px;
  }
`;

export const SpaceIconNoneHover = styled(Space)`
  min-height: 38px;
  padding: 8px;
  img {
    transition: all 0.2s ease-in-out;
    width: 28px;
    height: 28px;
  }

  &:hover {
    background: #faf1fa;
  }
`;

export const IconNoneHover = ({
  value,
  imgUrl,
  classStyle,
  onClick
}: {
  value?: number;
  imgUrl?: string;
  classStyle?: string;
  onClick: (...args: any) => void;
}) => (
  <SpaceIconNoneHover onClick={onClick} size={5}>
    {imgUrl && (
      <picture>
        <img className={classStyle} alt="burnIcon" src={imgUrl} />
      </picture>
    )}
    {value && <Counter num={value ?? 0} />}
  </SpaceIconNoneHover>
);

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-color-base);
  padding: 0.5rem;
  &.border-bottom {
    border-bottom: 1px solid var(--border-color-base);
  }
  .ant-space {
    gap: 4px !important;
  }
  .reaction-func {
    color: rgba(30, 26, 29, 0.6);
    cursor: pointer;
    display: flex;
    gap: 1rem;
    img {
      width: 28px;
      height: 28px;
      margin-right: 4px;
    }
  }
  @media (max-width: 520px) {
    padding: 4px;
  }
`;

type ActionPostBarProps = {
  post: PostQueryItem;
  onClickIconComment?: (e) => void;
  isSetBorderBottom?: boolean;
};

const AuthorizeIconNoneHover = WithAuthorizeAction(IconNoneHover);
const AuthorizeReaction = WithAuthorizeAction(BaseReaction);

const ActionPostBar = ({ post, onClickIconComment, isSetBorderBottom }: ActionPostBarProps) => {
  const dispatch = useSliceDispatch();
  const selectedAccount = useSliceSelector(getSelectedAccount);
  const slpBalancesAndUtxos = useSliceSelector(getSlpBalancesAndUtxos);
  const walletPaths = useSliceSelector(getAllWalletPaths);
  const Wallet = React.useContext(WalletContext);
  const router = useRouter();
  const selectedKey = router.pathname ?? '';
  const [borderBottom, setBorderBottom] = useState<Boolean>(false);
  const { XPI, chronik } = Wallet;
  const { sendCoin } = useCoin();

  const roundDanaViewScore = Math.round(post.danaViewScore || 0);

  const [repostFee, setRepostFee] = useState(0);
  const { data: dataFee } = useConvertDanaToCoinQuery({
    ConvertDanaInput: {
      convertToCoin: (selectedAccount?.coin ?? COIN.XPI) as unknown as Coin
    }
  });

  useEffect(() => {
    setRepostFee((dataFee?.convertDanaToCoin ?? 0) * Number(post?.page?.createPostFee) ?? 0);
  }, [dataFee]);

  useEffect(() => {
    selectedKey.includes('post') || isSetBorderBottom ? setBorderBottom(true) : setBorderBottom(false);
  }, [selectedKey]);

  const [repostTrigger, { isLoading: isLoadingRepost, isSuccess: isSuccessRepost, isError: isErrorRepost }] =
    useRepostMutation();

  const handleRepost = async (post: PostQueryItem) => {
    try {
      let txHex;

      try {
        if (
          !_.isNil(post?.page) &&
          selectedAccount.id != Number(post.page.pageAccount.id) &&
          parseFloat(post.page.createPostFee) != 0
        ) {
          const fundingWif = getUtxoWif(slpBalancesAndUtxos.nonSlpUtxos[0], walletPaths);
          const selectedCoin = selectedAccount?.coin ?? COIN.XPI;
          txHex = await sendCoin(
            selectedCoin,
            XPI,
            chronik,
            fundingWif,
            slpBalancesAndUtxos.nonSlpUtxos,
            undefined,
            false,
            false, // one to one mode
            null,
            post.page.pageAccount.hash160,
            repostFee,
            true // return hex
          );
        }
      } catch (error) {
        throw new Error(intl.get('post.insufficientFeeCreatePost'));
      }

      const repostInput: RepostInput = {
        accountId: selectedAccount.id,
        postId: post.id,
        txHex: txHex
      };

      await repostTrigger({ input: repostInput })
        .unwrap()
        .then(() =>
          dispatch(
            showToast('success', {
              message: 'Success',
              description: intl.get('post.repostSuccessful'),
              duration: 5
            })
          )
        );
    } catch (e) {
      let message = e.message || e.error || JSON.stringify(e);
      if (isErrorRepost) {
        message = intl.get('post.repostFailure');
      }
      if (e.message === intl.get('post.insufficientFeeCreatePost')) {
        message = e.message;
      }
      dispatch(
        showToast('error', {
          message: 'Error',
          description: message,
          duration: 5
        })
      );
    }
  };

  return (
    <ActionBar className={`action-post-bar ${borderBottom ? 'border-bottom' : ''}`}>
      <GroupIconText>
        <AuthorizeReaction dataItem={post} burnForType={BurnForType.Post} />
        <AuthorizeIconNoneHover
          value={formatBalance(post?.totalComments ?? 0)}
          imgUrl="/images/ico-comments.svg"
          key={`list-vertical-comment-o-${post.id}`}
          classStyle="custom-comment"
          onClick={e => onClickIconComment(e)}
        />

        {/* Currently only apply repost to posts in the page and profile */}
        {(post.page || post.account.id === selectedAccount?.id) && (
          <Tooltip
            title={`${intl.get('page.repostFee')}: ${repostFee ?? 0} ${coinInfo[selectedAccount?.coin ?? COIN.XPI].ticker}`}
          >
            <Space style={{ padding: '8px' }} className="repost" size={5} onClick={() => handleRepost(post)}>
              <RetweetOutlined />
              <Counter isShowDana={false} num={post.reposts?.length ?? 0} />
            </Space>
          </Tooltip>
        )}
        <Tooltip title={`${intl.get('post.danaViewScore')}: ${roundDanaViewScore}`}>
          <Space style={{ padding: '8px' }} className="dana-view-score" size={5}>
            <BarChartOutlined />
            <Counter numberAbbreviation={true} isShowDana={false} num={roundDanaViewScore} />
          </Space>
        </Tooltip>
      </GroupIconText>

      <ShareSocialButton
        slug={post.id}
        content={post.content}
        accountName={post.account.name}
        shareForType={ShareForType.POST}
      />
    </ActionBar>
  );
};

export default React.memo(ActionPostBar);
