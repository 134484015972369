import { Token } from '@bcpros/lixi-models/lib/token/token.model';
import { createEntityAdapter, createReducer } from '@reduxjs/toolkit';

import { fetchAllTokensSuccess, getTokenSuccess, postTokenSuccess, selectToken } from './action';
import { TokenState } from './state';

export const tokenAdapter = createEntityAdapter<Token>();

const initialState: TokenState = tokenAdapter.getInitialState({
  selectedTokenId: {},
  getTokenById: {}
});

export const tokenReducer = createReducer(initialState, builder => {
  builder
    .addCase(postTokenSuccess, (state, action) => {
      const token: any = action.payload;
      tokenAdapter.upsertOne(state, token as Token);
    })
    .addCase(getTokenSuccess, (state, action) => {
      const tokenInfo = action.payload;
      state.getTokenById = tokenInfo;
    })
    .addCase(fetchAllTokensSuccess, (state, action) => {
      tokenAdapter.setAll(state, action.payload);
    })
    .addCase(selectToken, (state, action) => {
      const tokenInfo = action.payload;
      state.selectedTokenId = tokenInfo;
    });
});
