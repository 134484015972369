/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { AccountFieldsFragmentDoc } from '../account/accounts.generated';
import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type CheckIfFollowAccountQueryVariables = Types.Exact<{
  followingAccountId: Types.Scalars['Int']['input'];
}>;

export type CheckIfFollowAccountQuery = { __typename?: 'Query'; checkIfFollowAccount: boolean };

export type AllFollowersByFollowingQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.AccountOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  followingAccountId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AllFollowersByFollowingQuery = {
  __typename?: 'Query';
  allFollowersByFollowing: {
    __typename?: 'AccountConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'AccountEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        role: Types.Role;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type AllFollowingsByFollowerQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.AccountOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  followerAccountId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AllFollowingsByFollowerQuery = {
  __typename?: 'Query';
  allFollowingsByFollower: {
    __typename?: 'AccountConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'AccountEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        role: Types.Role;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type FollowAccountFieldsFragment = {
  __typename?: 'FollowAccount';
  id?: string | null;
  followerAccountId?: number | null;
  followingAccountId?: number | null;
  isFollowed?: boolean | null;
  createdAt: any;
  updatedAt: any;
  followerAccount?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
  followingAccount?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
};

export type CreateFollowAccountMutationVariables = Types.Exact<{
  input: Types.CreateFollowAccountInput;
}>;

export type CreateFollowAccountMutation = {
  __typename?: 'Mutation';
  createFollowAccount: {
    __typename?: 'FollowAccount';
    id?: string | null;
    followerAccountId?: number | null;
    followingAccountId?: number | null;
    isFollowed?: boolean | null;
    createdAt: any;
    updatedAt: any;
    followerAccount?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    followingAccount?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
  };
};

export type DeleteFollowAccountMutationVariables = Types.Exact<{
  input: Types.DeleteFollowAccountInput;
}>;

export type DeleteFollowAccountMutation = { __typename?: 'Mutation'; deleteFollowAccount: boolean };

export type CheckIfFollowPageQueryVariables = Types.Exact<{
  pageId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CheckIfFollowPageQuery = { __typename?: 'Query'; checkIfFollowPage: boolean };

export type CheckIfFollowTokenQueryVariables = Types.Exact<{
  tokenId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CheckIfFollowTokenQuery = { __typename?: 'Query'; checkIfFollowToken: boolean };

export type FollowPageFieldsFragment = {
  __typename?: 'FollowPage';
  id?: string | null;
  accountId?: number | null;
  pageId?: string | null;
  tokenId?: string | null;
  isFollowed?: boolean | null;
  createdAt: any;
  updatedAt: any;
  account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
  page?: {
    __typename?: 'Page';
    id: string;
    name: string;
    avatar?: string | null;
    cover?: string | null;
    totalBurnForPage?: number | null;
    category?: { __typename?: 'Category'; id: string; name: string } | null;
  } | null;
  token?: { __typename?: 'Token'; id: string; tokenId: string; name: string } | null;
};

export type CreateFollowPageMutationVariables = Types.Exact<{
  input: Types.CreateFollowPageInput;
}>;

export type CreateFollowPageMutation = {
  __typename?: 'Mutation';
  createFollowPage: {
    __typename?: 'FollowPage';
    id?: string | null;
    accountId?: number | null;
    pageId?: string | null;
    tokenId?: string | null;
    isFollowed?: boolean | null;
    createdAt: any;
    updatedAt: any;
    account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    page?: {
      __typename?: 'Page';
      id: string;
      name: string;
      avatar?: string | null;
      cover?: string | null;
      totalBurnForPage?: number | null;
      category?: { __typename?: 'Category'; id: string; name: string } | null;
    } | null;
    token?: { __typename?: 'Token'; id: string; tokenId: string; name: string } | null;
  };
};

export type DeleteFollowPageMutationVariables = Types.Exact<{
  input: Types.DeleteFollowPageInput;
}>;

export type DeleteFollowPageMutation = { __typename?: 'Mutation'; deleteFollowPage: boolean };

export type CreateFollowTokenMutationVariables = Types.Exact<{
  input: Types.CreateFollowTokenInput;
}>;

export type CreateFollowTokenMutation = {
  __typename?: 'Mutation';
  createFollowToken: {
    __typename?: 'FollowPage';
    id?: string | null;
    accountId?: number | null;
    pageId?: string | null;
    tokenId?: string | null;
    isFollowed?: boolean | null;
    createdAt: any;
    updatedAt: any;
    account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    page?: {
      __typename?: 'Page';
      id: string;
      name: string;
      avatar?: string | null;
      cover?: string | null;
      totalBurnForPage?: number | null;
      category?: { __typename?: 'Category'; id: string; name: string } | null;
    } | null;
    token?: { __typename?: 'Token'; id: string; tokenId: string; name: string } | null;
  };
};

export type DeleteFollowTokenMutationVariables = Types.Exact<{
  input: Types.DeleteFollowTokenInput;
}>;

export type DeleteFollowTokenMutation = { __typename?: 'Mutation'; deleteFollowToken: boolean };

export const FollowAccountFieldsFragmentDoc = `
    fragment FollowAccountFields on FollowAccount {
  id
  followerAccountId
  followerAccount {
    id
    name
    address
  }
  followingAccountId
  followingAccount {
    id
    name
    address
  }
  isFollowed
  createdAt
  updatedAt
}
    `;
export const FollowPageFieldsFragmentDoc = `
    fragment FollowPageFields on FollowPage {
  id
  accountId
  account {
    id
    name
    address
  }
  pageId
  page {
    id
    name
    avatar
    cover
    category {
      id
      name
    }
    totalBurnForPage
  }
  tokenId
  token {
    id
    tokenId
    name
  }
  isFollowed
  createdAt
  updatedAt
}
    `;
export const CheckIfFollowAccountDocument = `
    query checkIfFollowAccount($followingAccountId: Int!) {
  checkIfFollowAccount(followingAccountId: $followingAccountId)
}
    `;
export const AllFollowersByFollowingDocument = `
    query allFollowersByFollowing($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: AccountOrder, $skip: Int, $followingAccountId: Int) {
  allFollowersByFollowing(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
    followingAccountId: $followingAccountId
  ) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const AllFollowingsByFollowerDocument = `
    query allFollowingsByFollower($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: AccountOrder, $skip: Int, $followerAccountId: Int) {
  allFollowingsByFollower(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
    followerAccountId: $followerAccountId
  ) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const CreateFollowAccountDocument = `
    mutation createFollowAccount($input: CreateFollowAccountInput!) {
  createFollowAccount(data: $input) {
    ...FollowAccountFields
  }
}
    ${FollowAccountFieldsFragmentDoc}`;
export const DeleteFollowAccountDocument = `
    mutation deleteFollowAccount($input: DeleteFollowAccountInput!) {
  deleteFollowAccount(data: $input)
}
    `;
export const CheckIfFollowPageDocument = `
    query checkIfFollowPage($pageId: String) {
  checkIfFollowPage(pageId: $pageId)
}
    `;
export const CheckIfFollowTokenDocument = `
    query checkIfFollowToken($tokenId: String) {
  checkIfFollowToken(tokenId: $tokenId)
}
    `;
export const CreateFollowPageDocument = `
    mutation createFollowPage($input: CreateFollowPageInput!) {
  createFollowPage(data: $input) {
    ...FollowPageFields
  }
}
    ${FollowPageFieldsFragmentDoc}`;
export const DeleteFollowPageDocument = `
    mutation deleteFollowPage($input: DeleteFollowPageInput!) {
  deleteFollowPage(data: $input)
}
    `;
export const CreateFollowTokenDocument = `
    mutation createFollowToken($input: CreateFollowTokenInput!) {
  createFollowToken(data: $input) {
    ...FollowPageFields
  }
}
    ${FollowPageFieldsFragmentDoc}`;
export const DeleteFollowTokenDocument = `
    mutation deleteFollowToken($input: DeleteFollowTokenInput!) {
  deleteFollowToken(data: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    checkIfFollowAccount: build.query<CheckIfFollowAccountQuery, CheckIfFollowAccountQueryVariables>({
      query: variables => ({ document: CheckIfFollowAccountDocument, variables })
    }),
    allFollowersByFollowing: build.query<AllFollowersByFollowingQuery, AllFollowersByFollowingQueryVariables | void>({
      query: variables => ({ document: AllFollowersByFollowingDocument, variables })
    }),
    allFollowingsByFollower: build.query<AllFollowingsByFollowerQuery, AllFollowingsByFollowerQueryVariables | void>({
      query: variables => ({ document: AllFollowingsByFollowerDocument, variables })
    }),
    createFollowAccount: build.mutation<CreateFollowAccountMutation, CreateFollowAccountMutationVariables>({
      query: variables => ({ document: CreateFollowAccountDocument, variables })
    }),
    deleteFollowAccount: build.mutation<DeleteFollowAccountMutation, DeleteFollowAccountMutationVariables>({
      query: variables => ({ document: DeleteFollowAccountDocument, variables })
    }),
    checkIfFollowPage: build.query<CheckIfFollowPageQuery, CheckIfFollowPageQueryVariables | void>({
      query: variables => ({ document: CheckIfFollowPageDocument, variables })
    }),
    checkIfFollowToken: build.query<CheckIfFollowTokenQuery, CheckIfFollowTokenQueryVariables | void>({
      query: variables => ({ document: CheckIfFollowTokenDocument, variables })
    }),
    createFollowPage: build.mutation<CreateFollowPageMutation, CreateFollowPageMutationVariables>({
      query: variables => ({ document: CreateFollowPageDocument, variables })
    }),
    deleteFollowPage: build.mutation<DeleteFollowPageMutation, DeleteFollowPageMutationVariables>({
      query: variables => ({ document: DeleteFollowPageDocument, variables })
    }),
    createFollowToken: build.mutation<CreateFollowTokenMutation, CreateFollowTokenMutationVariables>({
      query: variables => ({ document: CreateFollowTokenDocument, variables })
    }),
    deleteFollowToken: build.mutation<DeleteFollowTokenMutation, DeleteFollowTokenMutationVariables>({
      query: variables => ({ document: DeleteFollowTokenDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
