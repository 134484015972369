/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { api } from '@store/baseApi';
export type AllPaymenMethodQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AllPaymenMethodQuery = {
  __typename?: 'Query';
  allPaymenMethod: Array<{ __typename?: 'PaymentMethod'; id: number; name: string }>;
};

export const AllPaymenMethodDocument = `
    query AllPaymenMethod {
  allPaymenMethod {
    id
    name
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    AllPaymenMethod: build.query<AllPaymenMethodQuery, AllPaymenMethodQueryVariables | void>({
      query: variables => ({ document: AllPaymenMethodDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
