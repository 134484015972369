/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated/types.generated';

import { api } from '@store/baseApi';
export type GetFiatRateQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetFiatRateQuery = {
  __typename?: 'Query';
  getFiatRate: Array<{
    __typename?: 'FiatRates';
    currency: string;
    fiatRates: Array<{
      __typename?: 'CurrencyRates';
      coin: string;
      rates: Array<{ __typename?: 'RateEntry'; ts: number; rate: number }>;
    }>;
  }>;
};

export const GetFiatRateDocument = `
    query GetFiatRate {
  getFiatRate {
    currency
    fiatRates {
      coin
      rates {
        ts
        rate
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    GetFiatRate: build.query<GetFiatRateQuery, GetFiatRateQueryVariables | void>({
      query: variables => ({ document: GetFiatRateDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
