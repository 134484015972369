/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type HashtagFieldsFragment = {
  __typename?: 'Hashtag';
  id: string;
  content: string;
  normalizedContent: string;
  danaBurnUp: number;
  danaBurnDown: number;
  danaBurnScore: number;
  createdAt?: any | null;
  updatedAt?: any | null;
};

export type HashtagQueryVariables = Types.Exact<{
  content: Types.Scalars['String']['input'];
}>;

export type HashtagQuery = {
  __typename?: 'Query';
  hashtag: {
    __typename?: 'Hashtag';
    id: string;
    content: string;
    normalizedContent: string;
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    createdAt?: any | null;
    updatedAt?: any | null;
  };
};

export type HashtagsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.HashtagOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type HashtagsQuery = {
  __typename?: 'Query';
  allHashtag: {
    __typename?: 'HashtagConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'HashtagEdge';
      cursor: string;
      node: {
        __typename?: 'Hashtag';
        id: string;
        content: string;
        normalizedContent: string;
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        createdAt?: any | null;
        updatedAt?: any | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type HashtagsByPageQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.HashtagOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type HashtagsByPageQuery = {
  __typename?: 'Query';
  allHashtagByPage: {
    __typename?: 'HashtagConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'HashtagEdge';
      cursor: string;
      node: {
        __typename?: 'Hashtag';
        id: string;
        content: string;
        normalizedContent: string;
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        createdAt?: any | null;
        updatedAt?: any | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type HashtagsByTokenQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.HashtagOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type HashtagsByTokenQuery = {
  __typename?: 'Query';
  allHashtagByToken: {
    __typename?: 'HashtagConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'HashtagEdge';
      cursor: string;
      node: {
        __typename?: 'Hashtag';
        id: string;
        content: string;
        normalizedContent: string;
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        createdAt?: any | null;
        updatedAt?: any | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type HashtagBySearchQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type HashtagBySearchQuery = {
  __typename?: 'Query';
  allHashtagBySearch: {
    __typename?: 'HashtagConnection';
    edges?: Array<{
      __typename?: 'HashtagEdge';
      cursor: string;
      node: {
        __typename?: 'Hashtag';
        id: string;
        content: string;
        normalizedContent: string;
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        createdAt?: any | null;
        updatedAt?: any | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export const HashtagFieldsFragmentDoc = `
    fragment HashtagFields on Hashtag {
  id
  content
  normalizedContent
  danaBurnUp
  danaBurnDown
  danaBurnScore
  createdAt
  updatedAt
}
    `;
export const HashtagDocument = `
    query Hashtag($content: String!) {
  hashtag(content: $content) {
    ...HashtagFields
  }
}
    ${HashtagFieldsFragmentDoc}`;
export const HashtagsDocument = `
    query Hashtags($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: HashtagOrder, $skip: Int) {
  allHashtag(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...HashtagFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${HashtagFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const HashtagsByPageDocument = `
    query HashtagsByPage($after: String, $before: String, $first: Int = 20, $last: Int, $id: String, $orderBy: HashtagOrder, $skip: Int) {
  allHashtagByPage(
    after: $after
    before: $before
    first: $first
    last: $last
    id: $id
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...HashtagFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${HashtagFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const HashtagsByTokenDocument = `
    query HashtagsByToken($after: String, $before: String, $first: Int = 20, $last: Int, $id: String, $orderBy: HashtagOrder, $skip: Int) {
  allHashtagByToken(
    after: $after
    before: $before
    first: $first
    last: $last
    id: $id
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...HashtagFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${HashtagFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const HashtagBySearchDocument = `
    query HashtagBySearch($after: String, $before: String, $first: Int, $last: Int, $query: String) {
  allHashtagBySearch(
    after: $after
    before: $before
    first: $first
    last: $last
    query: $query
  ) {
    edges {
      cursor
      node {
        ...HashtagFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${HashtagFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Hashtag: build.query<HashtagQuery, HashtagQueryVariables>({
      query: variables => ({ document: HashtagDocument, variables })
    }),
    Hashtags: build.query<HashtagsQuery, HashtagsQueryVariables | void>({
      query: variables => ({ document: HashtagsDocument, variables })
    }),
    HashtagsByPage: build.query<HashtagsByPageQuery, HashtagsByPageQueryVariables | void>({
      query: variables => ({ document: HashtagsByPageDocument, variables })
    }),
    HashtagsByToken: build.query<HashtagsByTokenQuery, HashtagsByTokenQueryVariables | void>({
      query: variables => ({ document: HashtagsByTokenDocument, variables })
    }),
    HashtagBySearch: build.query<HashtagBySearchQuery, HashtagBySearchQueryVariables | void>({
      query: variables => ({ document: HashtagBySearchDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
