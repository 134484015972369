/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type CommentQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type CommentQuery = {
  __typename?: 'Query';
  comment: {
    __typename?: 'Comment';
    id: string;
    commentText: string;
    commentByPublicKey?: string | null;
    commentableId?: string | null;
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    createdAt: any;
    updatedAt: any;
    parentId?: string | null;
    parent?: {
      __typename?: 'Comment';
      id: string;
      commentText: string;
      commentByPublicKey?: string | null;
      commentableId?: string | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      createdAt: any;
      updatedAt: any;
      parentId?: string | null;
      commentAccount: {
        __typename?: 'Account';
        address: string;
        hash160?: string | null;
        id: number;
        name: string;
        avatar?: string | null;
      };
      commentDana?: {
        __typename?: 'CommentDana';
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        version: number;
      } | null;
      imageUploadable?: {
        __typename?: 'ImageUploadable';
        id: string;
        uploads: Array<{
          __typename?: 'Upload';
          id: string;
          width?: number | null;
          height?: number | null;
          cfImageId?: string | null;
          cfImageFilename?: string | null;
        }>;
      } | null;
      commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
    } | null;
    commentAccount: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
    };
    commentDana?: {
      __typename?: 'CommentDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      version: number;
    } | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
  };
};

export type CommentsToCommentableIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.CommentOrder>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type CommentsToCommentableIdQuery = {
  __typename?: 'Query';
  commentsToCommentableId: {
    __typename?: 'CommentConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'CommentEdge';
      cursor: string;
      node: {
        __typename?: 'Comment';
        id: string;
        commentText: string;
        commentByPublicKey?: string | null;
        commentableId?: string | null;
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        createdAt: any;
        updatedAt: any;
        parentId?: string | null;
        parent?: {
          __typename?: 'Comment';
          id: string;
          commentText: string;
          commentByPublicKey?: string | null;
          commentableId?: string | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          createdAt: any;
          updatedAt: any;
          parentId?: string | null;
          commentAccount: {
            __typename?: 'Account';
            address: string;
            hash160?: string | null;
            id: number;
            name: string;
            avatar?: string | null;
          };
          commentDana?: {
            __typename?: 'CommentDana';
            danaBurnUp: number;
            danaBurnDown: number;
            danaBurnScore: number;
            version: number;
          } | null;
          imageUploadable?: {
            __typename?: 'ImageUploadable';
            id: string;
            uploads: Array<{
              __typename?: 'Upload';
              id: string;
              width?: number | null;
              height?: number | null;
              cfImageId?: string | null;
              cfImageFilename?: string | null;
            }>;
          } | null;
          commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
        } | null;
        commentAccount: {
          __typename?: 'Account';
          address: string;
          hash160?: string | null;
          id: number;
          name: string;
          avatar?: string | null;
        };
        commentDana?: {
          __typename?: 'CommentDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          version: number;
        } | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
        commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type CommentFieldsFragment = {
  __typename?: 'Comment';
  id: string;
  commentText: string;
  commentByPublicKey?: string | null;
  commentableId?: string | null;
  danaBurnUp: number;
  danaBurnDown: number;
  danaBurnScore: number;
  createdAt: any;
  updatedAt: any;
  parentId?: string | null;
  commentAccount: {
    __typename?: 'Account';
    address: string;
    hash160?: string | null;
    id: number;
    name: string;
    avatar?: string | null;
  };
  commentDana?: {
    __typename?: 'CommentDana';
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    version: number;
  } | null;
  imageUploadable?: {
    __typename?: 'ImageUploadable';
    id: string;
    uploads: Array<{
      __typename?: 'Upload';
      id: string;
      width?: number | null;
      height?: number | null;
      cfImageId?: string | null;
      cfImageFilename?: string | null;
    }>;
  } | null;
  commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
};

export type ReplyCommentFieldsFragment = {
  __typename?: 'Comment';
  id: string;
  commentText: string;
  commentByPublicKey?: string | null;
  commentableId?: string | null;
  danaBurnUp: number;
  danaBurnDown: number;
  danaBurnScore: number;
  createdAt: any;
  updatedAt: any;
  parentId?: string | null;
  parent?: {
    __typename?: 'Comment';
    id: string;
    commentText: string;
    commentByPublicKey?: string | null;
    commentableId?: string | null;
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    createdAt: any;
    updatedAt: any;
    parentId?: string | null;
    commentAccount: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
    };
    commentDana?: {
      __typename?: 'CommentDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      version: number;
    } | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
  } | null;
  commentAccount: {
    __typename?: 'Account';
    address: string;
    hash160?: string | null;
    id: number;
    name: string;
    avatar?: string | null;
  };
  commentDana?: {
    __typename?: 'CommentDana';
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    version: number;
  } | null;
  imageUploadable?: {
    __typename?: 'ImageUploadable';
    id: string;
    uploads: Array<{
      __typename?: 'Upload';
      id: string;
      width?: number | null;
      height?: number | null;
      cfImageId?: string | null;
      cfImageFilename?: string | null;
    }>;
  } | null;
  commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
};

export type CreateCommentMutationVariables = Types.Exact<{
  input: Types.CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: 'Mutation';
  createComment: {
    __typename?: 'Comment';
    id: string;
    commentText: string;
    commentByPublicKey?: string | null;
    commentableId?: string | null;
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    createdAt: any;
    updatedAt: any;
    parentId?: string | null;
    commentAccount: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
    };
    commentDana?: {
      __typename?: 'CommentDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      version: number;
    } | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
  };
};

export type CreateReplyCommentMutationVariables = Types.Exact<{
  input: Types.CreateCommentInput;
}>;

export type CreateReplyCommentMutation = {
  __typename?: 'Mutation';
  createReplyComment: {
    __typename?: 'Comment';
    id: string;
    commentText: string;
    commentByPublicKey?: string | null;
    commentableId?: string | null;
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    createdAt: any;
    updatedAt: any;
    parentId?: string | null;
    parent?: {
      __typename?: 'Comment';
      id: string;
      commentText: string;
      commentByPublicKey?: string | null;
      commentableId?: string | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      createdAt: any;
      updatedAt: any;
      parentId?: string | null;
      commentAccount: {
        __typename?: 'Account';
        address: string;
        hash160?: string | null;
        id: number;
        name: string;
        avatar?: string | null;
      };
      commentDana?: {
        __typename?: 'CommentDana';
        danaBurnUp: number;
        danaBurnDown: number;
        danaBurnScore: number;
        version: number;
      } | null;
      imageUploadable?: {
        __typename?: 'ImageUploadable';
        id: string;
        uploads: Array<{
          __typename?: 'Upload';
          id: string;
          width?: number | null;
          height?: number | null;
          cfImageId?: string | null;
          cfImageFilename?: string | null;
        }>;
      } | null;
      commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
    } | null;
    commentAccount: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
    };
    commentDana?: {
      __typename?: 'CommentDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      version: number;
    } | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    commentable?: { __typename?: 'Commentable'; id: string; type: Types.CommentType; commentToId: string } | null;
  };
};

export const CommentFieldsFragmentDoc = `
    fragment CommentFields on Comment {
  id
  commentText
  commentAccount {
    address
    hash160
    id
    name
    avatar
  }
  commentByPublicKey
  commentableId
  danaBurnUp
  danaBurnDown
  danaBurnScore
  commentDana {
    danaBurnUp
    danaBurnDown
    danaBurnScore
    version
  }
  imageUploadable {
    id
    uploads {
      id
      width
      height
      cfImageId
      cfImageFilename
    }
  }
  createdAt
  updatedAt
  commentable {
    id
    type
    commentToId
  }
  parentId
}
    `;
export const ReplyCommentFieldsFragmentDoc = `
    fragment ReplyCommentFields on Comment {
  ...CommentFields
  parent {
    ...CommentFields
  }
}
    ${CommentFieldsFragmentDoc}`;
export const CommentDocument = `
    query Comment($id: String!) {
  comment(id: $id) {
    ...ReplyCommentFields
  }
}
    ${ReplyCommentFieldsFragmentDoc}`;
export const CommentsToCommentableIdDocument = `
    query CommentsToCommentableId($after: String, $before: String, $first: Int = 20, $last: Int, $orderBy: CommentOrder, $id: String, $skip: Int) {
  commentsToCommentableId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    id: $id
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...ReplyCommentFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${ReplyCommentFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const CreateCommentDocument = `
    mutation createComment($input: CreateCommentInput!) {
  createComment(data: $input) {
    ...CommentFields
  }
}
    ${CommentFieldsFragmentDoc}`;
export const CreateReplyCommentDocument = `
    mutation createReplyComment($input: CreateCommentInput!) {
  createReplyComment(data: $input) {
    ...ReplyCommentFields
  }
}
    ${ReplyCommentFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Comment: build.query<CommentQuery, CommentQueryVariables>({
      query: variables => ({ document: CommentDocument, variables })
    }),
    CommentsToCommentableId: build.query<CommentsToCommentableIdQuery, CommentsToCommentableIdQueryVariables | void>({
      query: variables => ({ document: CommentsToCommentableIdDocument, variables })
    }),
    createComment: build.mutation<CreateCommentMutation, CreateCommentMutationVariables>({
      query: variables => ({ document: CreateCommentDocument, variables })
    }),
    createReplyComment: build.mutation<CreateReplyCommentMutation, CreateReplyCommentMutationVariables>({
      query: variables => ({ document: CreateReplyCommentDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
