export const countries = [
  {
    id: 'all',
    name: 'All of country'
  },
  {
    id: 'vn',
    name: 'Viet Nam'
  },
  {
    id: 'us',
    name: 'USA'
  },
  {
    id: 'id',
    name: 'Indonesia'
  },
  {
    id: 'ph',
    name: 'Philippines'
  }
];
