/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type BurnFieldsFragment = {
  __typename?: 'BurnItem';
  id: string;
  txid: string;
  burnType: boolean;
  burnForType: Types.BurnForTypeItem;
  burnForId: string;
  burnedValue: number;
  coinBurned?: Types.Coin | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  burnedBy: { __typename?: 'Account'; id: number; name: string; avatar?: string | null; address: string };
};

export type PostBurnHistoryQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type PostBurnHistoryQuery = {
  __typename?: 'Query';
  postBurnHistory: {
    __typename?: 'BurnBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'BurnItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'BurnItem';
        id: string;
        txid: string;
        burnType: boolean;
        burnForType: Types.BurnForTypeItem;
        burnForId: string;
        burnedValue: number;
        coinBurned?: Types.Coin | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        burnedBy: { __typename?: 'Account'; id: number; name: string; avatar?: string | null; address: string };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export const BurnFieldsFragmentDoc = `
    fragment BurnFields on BurnItem {
  id
  txid
  burnType
  burnForType
  burnForId
  burnedValue
  burnedBy {
    id
    name
    avatar
    address
  }
  coinBurned
  createdAt
  updatedAt
}
    `;
export const PostBurnHistoryDocument = `
    query PostBurnHistory($id: String!, $first: Int = 20, $after: String) {
  postBurnHistory(id: $id, first: $first, after: $after) {
    totalCount
    edges {
      cursor
      node {
        ...BurnFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${BurnFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    PostBurnHistory: build.query<PostBurnHistoryQuery, PostBurnHistoryQueryVariables>({
      query: variables => ({ document: PostBurnHistoryDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
