/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { api } from '@store/baseApi';
export type ConvertDanaToCoinQueryVariables = Types.Exact<{
  ConvertDanaInput: Types.ConvertDana;
}>;

export type ConvertDanaToCoinQuery = { __typename?: 'Query'; convertDanaToCoin: number };

export const ConvertDanaToCoinDocument = `
    query ConvertDanaToCoin($ConvertDanaInput: ConvertDana!) {
  convertDanaToCoin(ConvertDanaInput: $ConvertDanaInput)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    ConvertDanaToCoin: build.query<ConvertDanaToCoinQuery, ConvertDanaToCoinQueryVariables>({
      query: variables => ({ document: ConvertDanaToCoinDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
