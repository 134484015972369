export enum PollTypeSelect {
  single = 1,
  mutiple = 0
}

export enum PollTypeAdd {
  no = 0,
  yes = 1
}

export enum PollTime {
  closePoll = 'Final result'
}
