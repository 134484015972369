import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { FilterType } from '@bcpros/lixi-models/lib/filter';
import { getAccountInfoTemp } from '@store/account';
import { useSliceDispatch, useSliceSelector } from '@store/index';
import { saveMinimumDanaFilter, setNegativeDanaStatus } from '@store/settings/actions';
import { getMinimumDanaFilter, getNegativeDanaStatus } from '@store/settings/selectors';
import 'animate.css';
import { Button, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import styled from 'styled-components';

const FilterStyle = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;
  p {
    margin: 0;
    font-weight: 500;
  }

  .ant-input-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    Button {
      &.down-value {
        border: 0.1px solid;
        border-radius: 50%;
        color: rgba(30, 26, 29, 0.6);
      }
      &.down-value:hover {
        border: 1.3px solid #9e2a9c;
      }
      &.up-value {
        border: 0.1px solid;
        border-radius: 50%;
        color: rgba(30, 26, 29, 0.6);
      }
      &.up-value:hover {
        border: 1.3px solid #9e2a9c;
      }
    }
    .ant-input-disabled {
      width: 70px;
      color: #000;
      cursor: pointer;
      border: 0px;
      background: rgba(0, 0, 0, 0);
      padding: 0;
      text-align: center;
    }
  }
`;

const FilterContainer = styled.div``;

type FilterBurntProps = {
  filterType: FilterType;
};

export const FilterBurnt = ({ filterType }: FilterBurntProps) => {
  const dispatch = useSliceDispatch();
  const selectedAccount = useSliceSelector(getAccountInfoTemp);
  const minimumDanaFilter = useSliceSelector(getMinimumDanaFilter);
  const negativeDanaStatus = useSliceSelector(getNegativeDanaStatus);
  const filterLevel = [-1, 0, 1, 10, 100, 1000];

  const handleChangeAmount = (isIncrement: boolean) => {
    const currentLevel = filterLevel.indexOf(minimumDanaFilter);
    let valueToSave = isIncrement ? filterLevel[currentLevel + 1] : filterLevel[currentLevel - 1];

    if (valueToSave > 0) dispatch(setNegativeDanaStatus(false));

    dispatch(saveMinimumDanaFilter(valueToSave));
  };

  const onChange = (e: CheckboxChangeEvent) => {
    dispatch(setNegativeDanaStatus(e.target.checked));
  };

  useEffect(() => {
    if (_.isNil(minimumDanaFilter)) {
      dispatch(saveMinimumDanaFilter(0));
    }
  }, [minimumDanaFilter]);

  return (
    <React.Fragment>
      <FilterContainer>
        <FilterStyle>
          <p>{intl.get('general.level')}: </p>
          <Input.Group>
            <Button
              className="down-value"
              icon={<MinusOutlined />}
              onClick={() => handleChangeAmount(false)}
              disabled={
                selectedAccount && selectedAccount?.accountDana?.danaGiven > 0
                  ? minimumDanaFilter === -1
                  : minimumDanaFilter === 0
              }
            />
            <Input disabled value={minimumDanaFilter !== -1 ? minimumDanaFilter + intl.get('general.dana') : 'All'} />
            <Button
              className="up-value"
              icon={<PlusOutlined />}
              onClick={() => handleChangeAmount(true)}
              disabled={minimumDanaFilter === 1000}
            />
          </Input.Group>
        </FilterStyle>
      </FilterContainer>
    </React.Fragment>
  );
};
