/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated/types.generated';

import { DisputeFieldsFragmentDoc, TimelineItemFieldsFragmentDoc } from '../../timeline/timeline.generated';
import { BasicPageInfoFieldsFragmentDoc } from '../../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type DisputeQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type DisputeQuery = {
  __typename?: 'Query';
  dispute: {
    __typename?: 'Dispute';
    id: string;
    reason?: string | null;
    createdBy: string;
    createdAt: any;
    updatedAt: any;
    disputeStatus: Types.DisputeStatus;
    escrowOrder: {
      __typename?: 'EscrowOrder';
      amount: number;
      id: string;
      buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
      sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
      offer: {
        __typename?: 'Offer';
        message: string;
        createdAt: any;
        country?: { __typename?: 'Country'; name?: string | null } | null;
        state?: { __typename?: 'State'; name?: string | null } | null;
      };
    };
  };
};

export type AllDisputeByAccountQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  disputeStatus: Types.DisputeStatus;
}>;

export type AllDisputeByAccountQuery = {
  __typename?: 'Query';
  allDisputeByAccount: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name?: string | null } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              buyerDepositTx?: string | null;
              price: string;
              amount: number;
              amountCoinOrCurrency: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: {
                __typename?: 'Offer';
                postId: string;
                message: string;
                coinPayment?: string | null;
                localCurrency?: string | null;
              };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                id: string;
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                noteOffer?: string | null;
                price: string;
                coinPayment?: string | null;
                marginPercentage: number;
                localCurrency?: string | null;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                stateId?: number | null;
                countryId?: number | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                state?: { __typename?: 'State'; name?: string | null } | null;
                country?: { __typename?: 'Country'; name?: string | null } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type CreateDisputeMutationVariables = Types.Exact<{
  input: Types.CreateDisputeInput;
}>;

export type CreateDisputeMutation = {
  __typename?: 'Mutation';
  createDispute: {
    __typename?: 'Dispute';
    id: string;
    reason?: string | null;
    createdBy: string;
    createdAt: any;
    updatedAt: any;
    disputeStatus: Types.DisputeStatus;
    escrowOrder: {
      __typename?: 'EscrowOrder';
      amount: number;
      id: string;
      buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
      sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
      offer: {
        __typename?: 'Offer';
        message: string;
        createdAt: any;
        country?: { __typename?: 'Country'; name?: string | null } | null;
        state?: { __typename?: 'State'; name?: string | null } | null;
      };
    };
  };
};

export type UpdateDisputeMutationVariables = Types.Exact<{
  input: Types.UpdateDisputeInput;
}>;

export type UpdateDisputeMutation = {
  __typename?: 'Mutation';
  updateDispute: {
    __typename?: 'Dispute';
    id: string;
    reason?: string | null;
    createdBy: string;
    createdAt: any;
    updatedAt: any;
    disputeStatus: Types.DisputeStatus;
    escrowOrder: {
      __typename?: 'EscrowOrder';
      amount: number;
      id: string;
      buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
      sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
      offer: {
        __typename?: 'Offer';
        message: string;
        createdAt: any;
        country?: { __typename?: 'Country'; name?: string | null } | null;
        state?: { __typename?: 'State'; name?: string | null } | null;
      };
    };
  };
};

export const DisputeDocument = `
    query Dispute($id: String!) {
  dispute(id: $id) {
    ...DisputeFields
  }
}
    ${DisputeFieldsFragmentDoc}`;
export const AllDisputeByAccountDocument = `
    query AllDisputeByAccount($first: Int = 20, $after: String, $disputeStatus: DisputeStatus!) {
  allDisputeByAccount(first: $first, after: $after, disputeStatus: $disputeStatus) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const CreateDisputeDocument = `
    mutation CreateDispute($input: CreateDisputeInput!) {
  createDispute(data: $input) {
    ...DisputeFields
  }
}
    ${DisputeFieldsFragmentDoc}`;
export const UpdateDisputeDocument = `
    mutation UpdateDispute($input: UpdateDisputeInput!) {
  updateDispute(data: $input) {
    ...DisputeFields
  }
}
    ${DisputeFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Dispute: build.query<DisputeQuery, DisputeQueryVariables>({
      query: variables => ({ document: DisputeDocument, variables })
    }),
    AllDisputeByAccount: build.query<AllDisputeByAccountQuery, AllDisputeByAccountQueryVariables>({
      query: variables => ({ document: AllDisputeByAccountDocument, variables })
    }),
    CreateDispute: build.mutation<CreateDisputeMutation, CreateDisputeMutationVariables>({
      query: variables => ({ document: CreateDisputeDocument, variables })
    }),
    UpdateDispute: build.mutation<UpdateDisputeMutation, UpdateDisputeMutationVariables>({
      query: variables => ({ document: UpdateDisputeDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
