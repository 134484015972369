import { PaginationArgs } from '@bcpros/lixi-models/core/pagination/pagination.args';
import { AccountQueryItem } from '../../generated/types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTopMonthAccountsQuery, useLazyTopMonthAccountsQuery } from './accounts.api';

const accountsAdapter = createEntityAdapter<AccountQueryItem, number>({
  selectId: account => account.id
});

const { selectAll } = accountsAdapter.getSelectors();

interface PaginationTopWeekAccount extends PaginationArgs {
  month: number;
  year: number;
}

export function useInfiniteTopMonthAccountsQuery(
  params: PaginationTopWeekAccount,
  fetchAll: boolean = false // if `true`: auto do next fetches to get all notes at once
) {
  const baseResult = useTopMonthAccountsQuery(params);

  const [trigger, nextResult] = useLazyTopMonthAccountsQuery();
  const [combinedData, setCombinedData] = useState(accountsAdapter.getInitialState({}));

  const isBaseReady = useRef(false);
  const isNextDone = useRef(true);

  // next: starts with a null, fetching ended with an undefined cursor
  const next = useRef<null | string | undefined>(null);

  const data = useMemo(() => {
    const result = selectAll(combinedData);
    return result;
  }, [combinedData]);

  // Base result
  useEffect(() => {
    next.current = baseResult.data?.topMonthAccountDanaGiven?.pageInfo?.endCursor;
    if (baseResult?.data?.topMonthAccountDanaGiven) {
      isBaseReady.current = true;

      const adapterSetAll = accountsAdapter.setAll(
        combinedData,
        baseResult.data.topMonthAccountDanaGiven.edges.map(item => item.node)
      );

      setCombinedData(adapterSetAll);
      fetchAll && fetchNext();
    }
  }, [baseResult]);

  const fetchNext = async () => {
    if (!isBaseReady.current || !isNextDone.current || next.current === undefined || next.current === null) {
      return;
    }

    try {
      isNextDone.current = false;
      await trigger({
        ...params,
        after: next.current
      });
    } catch (e) {
    } finally {
      isNextDone.current = true;
      fetchAll && fetchNext();
    }
  };

  const refetch = async () => {
    isBaseReady.current = false;
    next.current = null; // restart
    await baseResult.refetch(); // restart with a whole new refetching
  };

  return {
    data: data ?? [],
    totalCount: baseResult?.data?.topMonthAccountDanaGiven?.totalCount ?? 0,
    error: baseResult?.error,
    isError: baseResult?.isError,
    isLoading: baseResult?.isLoading,
    isFetching: baseResult?.isFetching || nextResult?.isFetching,
    errorNext: nextResult?.error,
    isErrorNext: nextResult?.isError,
    isFetchingNext: nextResult?.isFetching,
    hasNext: !!baseResult.data?.topMonthAccountDanaGiven?.pageInfo?.hasNextPage,
    fetchNext,
    refetch
  };
}
