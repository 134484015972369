/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

export type BasicPageInfoFieldsFragment = { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };

export const BasicPageInfoFieldsFragmentDoc = `
    fragment BasicPageInfoFields on BasicPageInfo {
  endCursor
  hasNextPage
}
    `;
