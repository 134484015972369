/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type TokenQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type TokenQuery = {
  __typename?: 'Query';
  token: {
    __typename?: 'Token';
    id: string;
    tokenId: string;
    tokenType: string;
    name: string;
    ticker: string;
    decimals: number;
    tokenDocumentUrl?: string | null;
    totalBurned?: string | null;
    totalMinted?: string | null;
    followersCount?: number | null;
    totalDanaViewScore?: number | null;
    initialTokenQuantity?: string | null;
    comments?: any | null;
    createdDate: any;
    isFollowed?: boolean | null;
    dana?: {
      __typename?: 'TokenDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
  };
};

export type TokenByTokenIdQueryVariables = Types.Exact<{
  tokenId: Types.Scalars['String']['input'];
}>;

export type TokenByTokenIdQuery = {
  __typename?: 'Query';
  tokenByTokenId: {
    __typename?: 'Token';
    id: string;
    tokenId: string;
    tokenType: string;
    name: string;
    ticker: string;
    decimals: number;
    tokenDocumentUrl?: string | null;
    totalBurned?: string | null;
    totalMinted?: string | null;
    followersCount?: number | null;
    totalDanaViewScore?: number | null;
    initialTokenQuantity?: string | null;
    comments?: any | null;
    createdDate: any;
    isFollowed?: boolean | null;
    dana?: {
      __typename?: 'TokenDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
  };
};

export type TokensQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type TokensQuery = {
  __typename?: 'Query';
  allTokens: {
    __typename?: 'TokenConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TokenBasicEdge';
      node: {
        __typename?: 'Token';
        id: string;
        tokenId: string;
        tokenType: string;
        name: string;
        ticker: string;
        decimals: number;
        tokenDocumentUrl?: string | null;
        totalBurned?: string | null;
        totalMinted?: string | null;
        followersCount?: number | null;
        totalDanaViewScore?: number | null;
        initialTokenQuantity?: string | null;
        comments?: any | null;
        createdDate: any;
        isFollowed?: boolean | null;
        dana?: {
          __typename?: 'TokenDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type TokenFieldsFragment = {
  __typename?: 'Token';
  id: string;
  tokenId: string;
  tokenType: string;
  name: string;
  ticker: string;
  decimals: number;
  tokenDocumentUrl?: string | null;
  totalBurned?: string | null;
  totalMinted?: string | null;
  followersCount?: number | null;
  totalDanaViewScore?: number | null;
  initialTokenQuantity?: string | null;
  comments?: any | null;
  createdDate: any;
  isFollowed?: boolean | null;
  dana?: {
    __typename?: 'TokenDana';
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    danaReceivedUp: number;
    danaReceivedDown: number;
    danaReceivedScore: number;
    version: number;
  } | null;
};

export type CreateTokenMutationVariables = Types.Exact<{
  input: Types.CreateTokenInput;
}>;

export type CreateTokenMutation = {
  __typename?: 'Mutation';
  createToken: {
    __typename?: 'Token';
    id: string;
    tokenId: string;
    tokenType: string;
    name: string;
    ticker: string;
    decimals: number;
    tokenDocumentUrl?: string | null;
    totalBurned?: string | null;
    totalMinted?: string | null;
    followersCount?: number | null;
    totalDanaViewScore?: number | null;
    initialTokenQuantity?: string | null;
    comments?: any | null;
    createdDate: any;
    isFollowed?: boolean | null;
    dana?: {
      __typename?: 'TokenDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
  };
};

export const TokenFieldsFragmentDoc = `
    fragment TokenFields on Token {
  id
  tokenId
  tokenType
  name
  ticker
  decimals
  tokenDocumentUrl
  totalBurned
  totalMinted
  followersCount
  totalDanaViewScore
  dana {
    danaBurnUp
    danaBurnDown
    danaBurnScore
    danaReceivedUp
    danaReceivedDown
    danaReceivedScore
    version
  }
  initialTokenQuantity
  comments
  createdDate
  isFollowed
}
    `;
export const TokenDocument = `
    query Token($id: String!) {
  token(id: $id) {
    ...TokenFields
  }
}
    ${TokenFieldsFragmentDoc}`;
export const TokenByTokenIdDocument = `
    query TokenByTokenId($tokenId: String!) {
  tokenByTokenId(tokenId: $tokenId) {
    ...TokenFields
  }
}
    ${TokenFieldsFragmentDoc}`;
export const TokensDocument = `
    query Tokens($after: String, $first: Int = 20, $skip: Int) {
  allTokens(after: $after, first: $first, skip: $skip) {
    totalCount
    edges {
      node {
        ...TokenFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TokenFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const CreateTokenDocument = `
    mutation createToken($input: CreateTokenInput!) {
  createToken(data: $input) {
    ...TokenFields
  }
}
    ${TokenFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Token: build.query<TokenQuery, TokenQueryVariables>({
      query: variables => ({ document: TokenDocument, variables })
    }),
    TokenByTokenId: build.query<TokenByTokenIdQuery, TokenByTokenIdQueryVariables>({
      query: variables => ({ document: TokenByTokenIdDocument, variables })
    }),
    Tokens: build.query<TokensQuery, TokensQueryVariables | void>({
      query: variables => ({ document: TokensDocument, variables })
    }),
    createToken: build.mutation<CreateTokenMutation, CreateTokenMutationVariables>({
      query: variables => ({ document: CreateTokenDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
