/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { api } from '@store/baseApi';
export type ProductQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    id: string;
    name: string;
    price: number;
    priceUnit: string;
    phoneNumber: string;
    title: string;
    categoryId?: number | null;
    description: string;
    imageUploadableId?: string | null;
    createdAt: any;
    updatedAt: any;
    page?: { __typename?: 'Page'; id: string; name: string; pageAccountId: number } | null;
  };
};

export type ProductFieldsFragment = {
  __typename?: 'Product';
  id: string;
  name: string;
  price: number;
  priceUnit: string;
  phoneNumber: string;
  title: string;
  categoryId?: number | null;
  description: string;
  imageUploadableId?: string | null;
  createdAt: any;
  updatedAt: any;
  page?: { __typename?: 'Page'; id: string; name: string; pageAccountId: number } | null;
};

export type CreateProductMutationVariables = Types.Exact<{
  input: Types.CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: 'Mutation';
  createProduct: {
    __typename?: 'Product';
    id: string;
    name: string;
    price: number;
    priceUnit: string;
    phoneNumber: string;
    title: string;
    categoryId?: number | null;
    description: string;
    imageUploadableId?: string | null;
    createdAt: any;
    updatedAt: any;
    page?: { __typename?: 'Page'; id: string; name: string; pageAccountId: number } | null;
  };
};

export const ProductFieldsFragmentDoc = `
    fragment ProductFields on Product {
  id
  page {
    id
    name
    pageAccountId
  }
  name
  price
  priceUnit
  phoneNumber
  title
  categoryId
  description
  imageUploadableId
  createdAt
  updatedAt
}
    `;
export const ProductDocument = `
    query Product($id: String!) {
  product(id: $id) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;
export const CreateProductDocument = `
    mutation createProduct($input: CreateProductInput!) {
  createProduct(data: $input) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Product: build.query<ProductQuery, ProductQueryVariables>({
      query: variables => ({ document: ProductDocument, variables })
    }),
    createProduct: build.mutation<CreateProductMutation, CreateProductMutationVariables>({
      query: variables => ({ document: CreateProductDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
