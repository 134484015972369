import { useSliceDispatch } from '@store/index';
import { closeModal } from '@store/modal/actions';
import { Modal } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { COIN } from '@bcpros/lixi-models/constants/coins/coin';
import { generateAccount } from '@store/account';
import intl from 'react-intl-universal';
import { AccountType, GenerateAccountType } from '@bcpros/lixi-models/constants/account';

const StyledModal = styled(Modal)`
  .ant-descriptions-bordered .ant-descriptions-view {
    border: none;
  }
  .ant-modal-body {
    border-radius: 20px !important;
  }

  .ant-descriptions-row {
    border-bottom: 0 !important;
    .ant-descriptions-item-content {
      input {
        border-color: var(--border-color-dark-base);
      }
    }
  }

  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 0px 24px;
    border-right: none;
  }

  .account-type {
    margin-top: 1rem;
  }
`;

const CreateAccountModal = () => {
  const dispatch = useSliceDispatch();
  const {
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [coin, setCoin] = useState<COIN>(COIN.XPI);
  const [accountType, setAccountType] = useState<AccountType>(AccountType.NORMAL);

  const onChangeCoin = (e: RadioChangeEvent) => {
    setCoin(e.target.value);
  };

  const onChangeAccountType = (e: RadioChangeEvent) => {
    setAccountType(e.target.value);
  };

  const handleOk = () => {
    const dataGenerateAccount: GenerateAccountType = {
      coin: coin ? coin : COIN.XPI,
      telegramId: undefined,
      accountType: accountType ? accountType : AccountType.NORMAL
    };
    dispatch(generateAccount(dataGenerateAccount));
    dispatch(closeModal());
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <StyledModal
      width={490}
      open={true}
      onOk={handleSubmit(handleOk)}
      onCancel={handleCancel}
      closable={false}
      title={<div className="custom-burn-header">{intl.get('account.chooseNewAccount')}</div>}
    >
      <div className="account-coin">
        <h2>Coin</h2>
        <Radio.Group onChange={onChangeCoin} value={coin}>
          {Object.values(COIN).map(key => (
            <Radio key={key} value={key}>
              {COIN[key]}
            </Radio>
          ))}
        </Radio.Group>
      </div>

      <div className="account-type">
        <h2>Account type</h2>
        <Radio.Group onChange={onChangeAccountType} value={accountType}>
          {Object.values(AccountType).map(key => (
            <Radio key={key} value={key}>
              {AccountType[key]}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </StyledModal>
  );
};

export default CreateAccountModal;
