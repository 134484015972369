export enum COIN {
  XPI = 'XPI',
  XEC = 'XEC',
  XRG = 'XRG'
}

export const TX_HISTORY_COUNT = 20;
export const TOKEN_ICON_URL = 'https://etoken-icons.s3.us-west-2.amazonaws.com';
export const TOKEN_LOGO = '/images/logo_secondary.png';

export const DUST = 'Dust';
