export const LIMIT_CONTENT_POST = 24000;
export const WARNING_NUMBER = 30;
export const POST_TYPE = {
  POST: 'POST',
  POLL: 'POLL',
  PRODUCT: 'PRODUCT',
  EVENT: 'EVENT',
  OFFER: 'OFFER'
};

export const POST_FLAG = {
  BURNED_BY_OTHERS: 0
};
