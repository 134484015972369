/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { api } from '@store/baseApi';
export type BoostFeeFieldsFragment = {
  __typename?: 'BoostFee';
  id: string;
  txid: string;
  boostType: Types.BoostType;
  boostForType: Types.BoostForType;
  boostedByHash: string;
  boostForId: string;
  boostedValue: number;
};

export type CreateBoostMutationVariables = Types.Exact<{
  data: Types.CreateBoostInput;
}>;

export type CreateBoostMutation = {
  __typename?: 'Mutation';
  createBoost: {
    __typename?: 'BoostFee';
    id: string;
    txid: string;
    boostType: Types.BoostType;
    boostForType: Types.BoostForType;
    boostedByHash: string;
    boostForId: string;
    boostedValue: number;
  };
};

export const BoostFeeFieldsFragmentDoc = `
    fragment BoostFeeFields on BoostFee {
  id
  txid
  boostType
  boostForType
  boostedByHash
  boostForId
  boostedValue
}
    `;
export const CreateBoostDocument = `
    mutation CreateBoost($data: CreateBoostInput!) {
  createBoost(data: $data) {
    ...BoostFeeFields
  }
}
    ${BoostFeeFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    CreateBoost: build.mutation<CreateBoostMutation, CreateBoostMutationVariables>({
      query: variables => ({ document: CreateBoostDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
