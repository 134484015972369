import enLang from './entries/en-US';
import viLang from './entries/vi-VN';

const AppLocale = {
  'en-US': enLang,
  'vi-VN': viLang
};

export const AppLanguageNotAutoTrans = {
  ab: 'Abkhazian ',
  aa: 'Afar ',
  af: 'Afrikaans ',
  sq: 'Albanian ',
  am: 'Amharic ',
  ar: 'Arabic ',
  hy: 'Armenian ',
  as: 'Assamese ',
  ay: 'Aymara ',
  az: 'Azerbaijan',
  ba: 'Bashkir ',
  eu: 'Basque ',
  bn: 'Bengali  ',
  dz: 'Bhutani ',
  bh: 'Bihari ',
  bi: 'Bislama ',
  br: 'Breton ',
  bg: 'Bulgarian ',
  my: 'Burmese ',
  be: 'Byelorussi',
  km: 'Cambodian ',
  ca: 'Catalan ',
  zh: 'Chinese ',
  co: 'Corsican ',
  hr: 'Croatian ',
  cs: 'Czech ',
  da: 'Danish ',
  nl: 'Dutch ',
  en: 'English ',
  eo: 'Esperanto ',
  et: 'Estonian ',
  fo: 'Faeroese ',
  fa: 'Farsi ',
  fj: 'Fiji ',
  fi: 'Finnish ',
  fr: 'French ',
  fy: 'Frisian ',
  gl: 'Galician ',
  gd: 'Gaelic (Sc',
  gv: 'Gaelic (Ma',
  ka: 'Georgian ',
  de: 'German ',
  el: 'Greek ',
  kl: 'Greenlandi',
  gn: 'Guarani ',
  gu: 'Gujarati ',
  ha: 'Hausa ',
  he: 'Hebrew ',
  hi: 'Hindi ',
  hu: 'Hungarian ',
  is: 'Icelandic ',
  id: 'Indonesian',
  ia: 'Interlingu',
  ie: 'Interlingu',
  iu: 'Inuktitut ',
  ik: 'Inupiak ',
  ga: 'Irish ',
  it: 'Italian ',
  ja: 'Japanese ',
  kn: 'Kannada ',
  ks: 'Kashmiri ',
  kk: 'Kazakh ',
  rw: 'Kinyarwand',
  ky: 'Kirghiz ',
  rn: 'Kirundi ',
  ko: 'Korean ',
  ku: 'Kurdish ',
  lo: 'Laothian ',
  la: 'Latin ',
  lv: 'Latvian  ',
  li: 'Limburgish',
  ln: 'Lingala ',
  lt: 'Lithuanian',
  mk: 'Macedonian',
  mg: 'Malagasy ',
  ms: 'Malay ',
  ml: 'Malayalam ',
  mt: 'Maltese ',
  mi: 'Maori ',
  mr: 'Marathi ',
  mo: 'Moldavian ',
  mn: 'Mongolian ',
  na: 'Nauru ',
  ne: 'Nepali ',
  no: 'Norwegian ',
  oc: 'Occitan ',
  or: 'Oriya ',
  om: 'Oromo  ',
  ps: 'Pashto ',
  pl: 'Polish ',
  pt: 'Portuguese',
  pa: 'Punjabi ',
  qu: 'Quechua ',
  rm: 'Rhaeto-Rom',
  ro: 'Romanian ',
  ru: 'Russian ',
  sm: 'Samoan ',
  sg: 'Sangro ',
  sa: 'Sanskrit ',
  sr: 'Serbian ',
  sh: 'Serbo-Croa',
  st: 'Sesotho ',
  tn: 'Setswana ',
  sn: 'Shona ',
  sd: 'Sindhi ',
  si: 'Sinhalese ',
  ss: 'Siswati ',
  sk: 'Slovak ',
  sl: 'Slovenian ',
  so: 'Somali ',
  es: 'Spanish ',
  su: 'Sundanese ',
  sw: 'Swahili  ',
  sv: 'Swedish ',
  tl: 'Tagalog ',
  tg: 'Tajik ',
  ta: 'Tamil ',
  tt: 'Tatar ',
  te: 'Telugu ',
  th: 'Thai ',
  bo: 'Tibetan ',
  ti: 'Tigrinya ',
  to: 'Tonga ',
  ts: 'Tsonga ',
  tr: 'Turkish ',
  tk: 'Turkmen ',
  tw: 'Twi ',
  ug: 'Uighur ',
  uk: 'Ukrainian ',
  ur: 'Urdu ',
  uz: 'Uzbek ',
  vi: 'Vietnamese',
  vo: 'Volapük ',
  cy: 'Welsh ',
  wo: 'Wolof ',
  xh: 'Xhosa ',
  yi: 'Yiddish ',
  yo: 'Yoruba ',
  zu: 'Zulu '
};

export default AppLocale;
