/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type PageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type PageQuery = {
  __typename?: 'Query';
  page: {
    __typename?: 'Page';
    id: string;
    pageAccountId: number;
    name: string;
    title?: string | null;
    categoryId?: number | null;
    description: string;
    avatar?: string | null;
    cover?: string | null;
    parentId?: string | null;
    countryId?: number | null;
    countryName?: string | null;
    stateId?: number | null;
    stateName?: string | null;
    address?: string | null;
    website?: string | null;
    totalBurnForPage?: number | null;
    followersCount?: number | null;
    createPostFee: string;
    createCommentFee: string;
    createdAt: any;
    updatedAt: any;
    totalPostsBurnUp: number;
    totalPostsBurnDown: number;
    totalPostsBurnScore: number;
    totalDanaViewScore?: number | null;
    pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    category?: { __typename?: 'Category'; id: string; name: string } | null;
    dana?: {
      __typename?: 'PageDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
  };
};

export type PagesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PagesQuery = {
  __typename?: 'Query';
  allPages: {
    __typename?: 'PageBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PageBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Page';
        id: string;
        pageAccountId: number;
        name: string;
        title?: string | null;
        categoryId?: number | null;
        description: string;
        avatar?: string | null;
        cover?: string | null;
        parentId?: string | null;
        countryId?: number | null;
        countryName?: string | null;
        stateId?: number | null;
        stateName?: string | null;
        address?: string | null;
        website?: string | null;
        totalBurnForPage?: number | null;
        followersCount?: number | null;
        createPostFee: string;
        createCommentFee: string;
        createdAt: any;
        updatedAt: any;
        totalPostsBurnUp: number;
        totalPostsBurnDown: number;
        totalPostsBurnScore: number;
        totalDanaViewScore?: number | null;
        pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        category?: { __typename?: 'Category'; id: string; name: string } | null;
        dana?: {
          __typename?: 'PageDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type PagesByFollowerQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PagesByFollowerQuery = {
  __typename?: 'Query';
  pagesByFollower: {
    __typename?: 'PageBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PageBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Page';
        id: string;
        pageAccountId: number;
        name: string;
        title?: string | null;
        categoryId?: number | null;
        description: string;
        avatar?: string | null;
        cover?: string | null;
        parentId?: string | null;
        countryId?: number | null;
        countryName?: string | null;
        stateId?: number | null;
        stateName?: string | null;
        address?: string | null;
        website?: string | null;
        totalBurnForPage?: number | null;
        followersCount?: number | null;
        createPostFee: string;
        createCommentFee: string;
        createdAt: any;
        updatedAt: any;
        totalPostsBurnUp: number;
        totalPostsBurnDown: number;
        totalPostsBurnScore: number;
        totalDanaViewScore?: number | null;
        pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        category?: { __typename?: 'Category'; id: string; name: string } | null;
        dana?: {
          __typename?: 'PageDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type PagesByUserIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type PagesByUserIdQuery = {
  __typename?: 'Query';
  allPagesByUserId: {
    __typename?: 'PageBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'PageBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Page';
        id: string;
        pageAccountId: number;
        name: string;
        title?: string | null;
        categoryId?: number | null;
        description: string;
        avatar?: string | null;
        cover?: string | null;
        parentId?: string | null;
        countryId?: number | null;
        countryName?: string | null;
        stateId?: number | null;
        stateName?: string | null;
        address?: string | null;
        website?: string | null;
        totalBurnForPage?: number | null;
        followersCount?: number | null;
        createPostFee: string;
        createCommentFee: string;
        createdAt: any;
        updatedAt: any;
        totalPostsBurnUp: number;
        totalPostsBurnDown: number;
        totalPostsBurnScore: number;
        totalDanaViewScore?: number | null;
        pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
        category?: { __typename?: 'Category'; id: string; name: string } | null;
        dana?: {
          __typename?: 'PageDana';
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
          version: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type PageFieldsFragment = {
  __typename?: 'Page';
  id: string;
  pageAccountId: number;
  name: string;
  title?: string | null;
  categoryId?: number | null;
  description: string;
  avatar?: string | null;
  cover?: string | null;
  parentId?: string | null;
  countryId?: number | null;
  countryName?: string | null;
  stateId?: number | null;
  stateName?: string | null;
  address?: string | null;
  website?: string | null;
  totalBurnForPage?: number | null;
  followersCount?: number | null;
  createPostFee: string;
  createCommentFee: string;
  createdAt: any;
  updatedAt: any;
  totalPostsBurnUp: number;
  totalPostsBurnDown: number;
  totalPostsBurnScore: number;
  totalDanaViewScore?: number | null;
  pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
  category?: { __typename?: 'Category'; id: string; name: string } | null;
  dana?: {
    __typename?: 'PageDana';
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    danaReceivedUp: number;
    danaReceivedDown: number;
    danaReceivedScore: number;
    version: number;
  } | null;
};

export type CreatePageMutationVariables = Types.Exact<{
  input: Types.CreatePageInput;
}>;

export type CreatePageMutation = {
  __typename?: 'Mutation';
  createPage: {
    __typename?: 'Page';
    id: string;
    pageAccountId: number;
    name: string;
    title?: string | null;
    categoryId?: number | null;
    description: string;
    avatar?: string | null;
    cover?: string | null;
    parentId?: string | null;
    countryId?: number | null;
    countryName?: string | null;
    stateId?: number | null;
    stateName?: string | null;
    address?: string | null;
    website?: string | null;
    totalBurnForPage?: number | null;
    followersCount?: number | null;
    createPostFee: string;
    createCommentFee: string;
    createdAt: any;
    updatedAt: any;
    totalPostsBurnUp: number;
    totalPostsBurnDown: number;
    totalPostsBurnScore: number;
    totalDanaViewScore?: number | null;
    pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    category?: { __typename?: 'Category'; id: string; name: string } | null;
    dana?: {
      __typename?: 'PageDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
  };
};

export type UpdatePageMutationVariables = Types.Exact<{
  input: Types.UpdatePageInput;
}>;

export type UpdatePageMutation = {
  __typename?: 'Mutation';
  updatePage: {
    __typename?: 'Page';
    id: string;
    pageAccountId: number;
    name: string;
    title?: string | null;
    categoryId?: number | null;
    description: string;
    avatar?: string | null;
    cover?: string | null;
    parentId?: string | null;
    countryId?: number | null;
    countryName?: string | null;
    stateId?: number | null;
    stateName?: string | null;
    address?: string | null;
    website?: string | null;
    totalBurnForPage?: number | null;
    followersCount?: number | null;
    createPostFee: string;
    createCommentFee: string;
    createdAt: any;
    updatedAt: any;
    totalPostsBurnUp: number;
    totalPostsBurnDown: number;
    totalPostsBurnScore: number;
    totalDanaViewScore?: number | null;
    pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    category?: { __typename?: 'Category'; id: string; name: string } | null;
    dana?: {
      __typename?: 'PageDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
  };
};

export const PageFieldsFragmentDoc = `
    fragment PageFields on Page {
  id
  pageAccountId
  pageAccount {
    id
    name
    address
    hash160
  }
  name
  title
  categoryId
  category {
    id
    name
  }
  description
  avatar
  cover
  parentId
  countryId
  countryName
  stateId
  stateName
  address
  website
  dana {
    danaBurnUp
    danaBurnDown
    danaBurnScore
    danaReceivedUp
    danaReceivedDown
    danaReceivedScore
    version
  }
  totalBurnForPage
  followersCount
  createPostFee
  createCommentFee
  createdAt
  updatedAt
  totalPostsBurnUp
  totalPostsBurnDown
  totalPostsBurnScore
  totalDanaViewScore
}
    `;
export const PageDocument = `
    query Page($id: String!) {
  page(id: $id) {
    ...PageFields
  }
}
    ${PageFieldsFragmentDoc}`;
export const PagesDocument = `
    query Pages($after: String, $before: String, $first: Int = 20, $last: Int, $skip: Int) {
  allPages(
    after: $after
    before: $before
    first: $first
    last: $last
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...PageFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${PageFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const PagesByFollowerDocument = `
    query PagesByFollower($after: String, $first: Int = 20) {
  pagesByFollower(after: $after, first: $first) {
    totalCount
    edges {
      cursor
      node {
        ...PageFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${PageFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const PagesByUserIdDocument = `
    query PagesByUserId($after: String, $first: Int = 20, $id: Int, $skip: Int) {
  allPagesByUserId(after: $after, first: $first, id: $id, skip: $skip) {
    totalCount
    edges {
      cursor
      node {
        ...PageFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${PageFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const CreatePageDocument = `
    mutation createPage($input: CreatePageInput!) {
  createPage(data: $input) {
    ...PageFields
  }
}
    ${PageFieldsFragmentDoc}`;
export const UpdatePageDocument = `
    mutation updatePage($input: UpdatePageInput!) {
  updatePage(data: $input) {
    ...PageFields
  }
}
    ${PageFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Page: build.query<PageQuery, PageQueryVariables>({
      query: variables => ({ document: PageDocument, variables })
    }),
    Pages: build.query<PagesQuery, PagesQueryVariables | void>({
      query: variables => ({ document: PagesDocument, variables })
    }),
    PagesByFollower: build.query<PagesByFollowerQuery, PagesByFollowerQueryVariables | void>({
      query: variables => ({ document: PagesByFollowerDocument, variables })
    }),
    PagesByUserId: build.query<PagesByUserIdQuery, PagesByUserIdQueryVariables | void>({
      query: variables => ({ document: PagesByUserIdDocument, variables })
    }),
    createPage: build.mutation<CreatePageMutation, CreatePageMutationVariables>({
      query: variables => ({ document: CreatePageDocument, variables })
    }),
    updatePage: build.mutation<UpdatePageMutation, UpdatePageMutationVariables>({
      query: variables => ({ document: UpdatePageDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
