/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type MessageFieldsFragment = {
  __typename?: 'Message';
  id: string;
  body?: string | null;
  isPageOwner?: boolean | null;
  createdAt?: any | null;
  updatedAt?: any | null;
  author: { __typename?: 'Account'; id: number; name: string; address: string };
  pageMessageSession?: { __typename?: 'PageMessageSession'; id: string } | null;
  imageUploadable?: {
    __typename?: 'ImageUploadable';
    id: string;
    uploads: Array<{
      __typename?: 'Upload';
      id: string;
      sha: string;
      bucket?: string | null;
      width?: number | null;
      height?: number | null;
      cfImageId?: string | null;
      cfImageFilename?: string | null;
    }>;
  } | null;
};

export type MessageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type MessageQuery = {
  __typename?: 'Query';
  message: {
    __typename?: 'Message';
    id: string;
    body?: string | null;
    isPageOwner?: boolean | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author: { __typename?: 'Account'; id: number; name: string; address: string };
    pageMessageSession?: { __typename?: 'PageMessageSession'; id: string } | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
  };
};

export type MessageByPageMessageSessionIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  orderBy?: Types.InputMaybe<Types.MessageOrder>;
  skip?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type MessageByPageMessageSessionIdQuery = {
  __typename?: 'Query';
  allMessageByPageMessageSessionId: {
    __typename?: 'MessageConnection';
    totalCount?: number | null;
    edges?: Array<{
      __typename?: 'MessageEdge';
      cursor: string;
      node: {
        __typename?: 'Message';
        id: string;
        body?: string | null;
        isPageOwner?: boolean | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        author: { __typename?: 'Account'; id: number; name: string; address: string };
        pageMessageSession?: { __typename?: 'PageMessageSession'; id: string } | null;
        imageUploadable?: {
          __typename?: 'ImageUploadable';
          id: string;
          uploads: Array<{
            __typename?: 'Upload';
            id: string;
            sha: string;
            bucket?: string | null;
            width?: number | null;
            height?: number | null;
            cfImageId?: string | null;
            cfImageFilename?: string | null;
          }>;
        } | null;
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type CreateMessageMutationVariables = Types.Exact<{
  input: Types.CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage: {
    __typename?: 'Message';
    id: string;
    body?: string | null;
    isPageOwner?: boolean | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    author: { __typename?: 'Account'; id: number; name: string; address: string };
    pageMessageSession?: { __typename?: 'PageMessageSession'; id: string } | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
  };
};

export const MessageFieldsFragmentDoc = `
    fragment MessageFields on Message {
  id
  body
  author {
    id
    name
    address
  }
  pageMessageSession {
    id
  }
  imageUploadable {
    id
    uploads {
      id
      sha
      bucket
      width
      height
      cfImageId
      cfImageFilename
    }
  }
  isPageOwner
  createdAt
  updatedAt
}
    `;
export const MessageDocument = `
    query Message($id: String!) {
  message(id: $id) {
    ...MessageFields
  }
}
    ${MessageFieldsFragmentDoc}`;
export const MessageByPageMessageSessionIdDocument = `
    query MessageByPageMessageSessionId($after: String, $before: String, $first: Int = 20, $last: Int, $id: String, $orderBy: MessageOrder, $skip: Int) {
  allMessageByPageMessageSessionId(
    after: $after
    before: $before
    first: $first
    last: $last
    id: $id
    orderBy: $orderBy
    skip: $skip
  ) {
    totalCount
    edges {
      cursor
      node {
        ...MessageFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${MessageFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const CreateMessageDocument = `
    mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(data: $input) {
    ...MessageFields
  }
}
    ${MessageFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Message: build.query<MessageQuery, MessageQueryVariables>({
      query: variables => ({ document: MessageDocument, variables })
    }),
    MessageByPageMessageSessionId: build.query<
      MessageByPageMessageSessionIdQuery,
      MessageByPageMessageSessionIdQueryVariables | void
    >({
      query: variables => ({ document: MessageByPageMessageSessionIdDocument, variables })
    }),
    CreateMessage: build.mutation<CreateMessageMutation, CreateMessageMutationVariables>({
      query: variables => ({ document: CreateMessageDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
