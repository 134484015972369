/* eslint-disable no-case-declarations */
import { COIN } from '@bcpros/lixi-models/constants/coins/coin';
import { coinInfo } from '@bcpros/lixi-models/constants/coins/coin-info';
import { POST_TYPE } from '@bcpros/lixi-models/constants/post';
import { PostsQueryTag } from '@bcpros/lixi-models/constants/postQueryTag';
import { WORSHIP_TYPES } from '@bcpros/lixi-models/constants/worship';
import { BurnCommand, BurnExtraArguments, BurnQueueCommand } from '@bcpros/lixi-models/lib/burn/burn.command';
import { Burn, BurnForType, BurnType } from '@bcpros/lixi-models/lib/burn/burn.model';
import { callConfig } from '../../context/shareContext';
import {
  Account,
  Comment,
  CommentType,
  CreateWorshipInput,
  OrderDirection,
  Page,
  Post,
  WorshipOrderField
} from '../../generated/types.generated';
import { PayloadAction } from '@reduxjs/toolkit';
import { setTransactionNotReady, setTransactionReady } from '@store/account/actions';
import { getSelectedAccount, getTransactionStatus } from '@store/account/selectors';
import { getFailQueue } from '@store/burn';
import { api as commentsApi } from '@store/comment/comments.api';
import { api as pagesApi } from '@store/page/pages.api';
import { api as accountApi } from '@store/account/accounts.api';
import { api as postsApi } from '@store/post/posts.api';
import { api as templeApi } from '@store/temple/temple.api';
import { api as timelineApi } from '@store/timeline/timeline.api';
import { showToast } from '@store/toast/actions';
import { api as tokenApi } from '@store/token/tokens.api';
import { getAllWalletPaths, getSlpBalancesAndUtxos, getWalletBalances } from '@store/wallet';
import { api as worshipApi } from '@store/worship/worshipedPerson.api';
import { fromCoinToSatoshis, fromSatoshisToCoin, fromSmallestDenomination, getUtxoWif } from '../../utils/cashMethods';
import BigNumber from 'bignumber.js';
import * as _ from 'lodash';
import intl from 'react-intl-universal';
import { buffers } from 'redux-saga';
import { actionChannel, all, call, flush, fork, getContext, put, select, take, takeLatest } from 'redux-saga/effects';
import { match } from 'ts-pattern';
import { hideLoading } from '../loading/actions';
import { getFilterPostsHome, getLevelFilter } from '../settings';
import {
  addBurnQueue,
  addBurnTransaction,
  burnForUpDownVote,
  burnForUpDownVoteFailure,
  burnForUpDownVoteSuccess,
  clearBurnQueue,
  clearFailQueue,
  createTxHex,
  moveAllBurnToFailQueue,
  prepareBurnCommand,
  removeBurnQueue,
  returnTxHex
} from './actions';
import burnApi from './api';

import { PatchCollection } from 'node_modules/@reduxjs/toolkit/dist/query/core/buildThunks';
import { LixiStoreStateInterface } from '../state';
import { BurnForItem } from '../../generated/types';

function* prepareBurnCommandSaga(
  action: PayloadAction<{
    isUpVote: boolean;
    burnForItem: BurnForItem;
    burnForType: BurnForType;
    burnValue: string;
    amountDana: number;
    coinBurned: COIN;
  }>
) {
  try {
    const { isUpVote, burnForItem, burnForType, burnValue, amountDana, coinBurned } = action.payload;

    const failQueue = yield select(getFailQueue);
    if (failQueue.length > 0) yield put(clearFailQueue());
    const walletPaths = yield select(getAllWalletPaths);
    const selectedAccount = yield select(getSelectedAccount);
    const slpBalancesAndUtxos = yield select(getSlpBalancesAndUtxos);
    const balances = yield select(getWalletBalances);
    const filterValue = yield select(getFilterPostsHome);
    const level = yield select(getLevelFilter);
    const selectedCoin = selectedAccount?.coin ?? COIN.XPI;

    const { getUtxosByCoin } = callConfig.call.walletContext;

    let utxos = slpBalancesAndUtxos.nonSlpUtxos;
    let totalBalalanceInSats = balances.totalBalanceInSatoshis;
    //wallet can't burn default use XPI wallet
    if (selectedCoin !== COIN.XPI && !coinInfo[selectedCoin].canBurn) {
      const xpiUtxos = yield getUtxosByCoin(COIN.XPI);
      utxos = xpiUtxos.nonSlpUtxos;
      totalBalalanceInSats = utxos.reduce((accumulate, currentValue) => accumulate + Number(currentValue.value), 0);
    }
    const typeAddress = coinBurned === COIN.XPI ? 'xAddress' : 'cashAddress';
    const fundingFirstUtxo = utxos[0];
    const currentWalletPath = walletPaths.filter(acc => acc[typeAddress] === fundingFirstUtxo.address).pop();
    const { hash160 } = currentWalletPath;

    const burnType = isUpVote ? BurnType.Up : BurnType.Down;
    const burnedBy = hash160;
    let burnForId = burnForItem.id.toString();

    let tipToHashes: { hash: string; amount: string }[] = [];
    const cashDecimals =
      coinBurned === COIN.XRG ? coinInfo[COIN.XRG].microCashDecimals : coinInfo[coinBurned].cashDecimals;
    let amountToTip = fromCoinToSatoshis(
      new BigNumber(burnValue).multipliedBy(coinInfo[coinBurned].burnFee),
      cashDecimals
    )
      .valueOf()
      .toString();
    //get dust if tip not enough or NaN value
    const numberAmountTip = parseFloat(amountToTip);
    if (Number.isNaN(numberAmountTip) || numberAmountTip < coinInfo[coinBurned].dustSats) {
      amountToTip = coinInfo[coinBurned].dustSats.toString();
    }

    switch (burnForType) {
      case BurnForType.Post:
        const post = burnForItem as Post;
        tipToHashes.push({
          hash: post.page ? post.page.pageAccount.hash160 : post.account.hash160,
          amount: amountToTip
        });
        break;
      case BurnForType.Page:
        const page = burnForItem as Page;
        tipToHashes.push({
          hash: page.pageAccount.hash160,
          amount: amountToTip
        });
        break;
      case BurnForType.Account:
        const account = burnForItem as Account;
        tipToHashes.push({
          hash: account.hash160,
          amount: amountToTip
        });
        burnForId = account?.hash160 ?? '';
        break;
      case BurnForType.Comment:
        const comment = burnForItem as Comment;
        const commentToId = comment.commentable.commentToId;
        if (comment.commentable.type === CommentType.Post) {
          const promise = yield put(postsApi.endpoints.Post.initiate({ id: commentToId }));
          yield promise;
          const { post }: { post: Post } = yield promise.unwrap();
          const page = post?.page;
          const pageHash160 = page ? page?.pageAccount?.hash160 : undefined;
          const postHash160 = post.account.hash160;
          tipToHashes.push({
            hash: pageHash160 ?? postHash160,
            amount: amountToTip
          });
        }
        break;
    }

    tipToHashes = tipToHashes.filter(item => item.hash != selectedAccount?.hash160);
    const totalTip = fromSmallestDenomination(
      tipToHashes.reduce((total, item) => total + parseFloat(item.amount), 0),
      cashDecimals
    );
    if (
      utxos.length == 0 ||
      fromSmallestDenomination(totalBalalanceInSats, cashDecimals) < parseInt(burnValue) + totalTip
    ) {
      throw new Error(intl.get('account.insufficientFunds'));
    }

    const extraArguments: BurnExtraArguments = match(burnForType)
      .with(BurnForType.Post, () => {
        const post = burnForItem as Post;
        return {
          postQueryTags: [PostsQueryTag.Post],
          postId: burnForItem.id.toString(),
          minBurnFilter: filterValue,
          level: level,
          pageId: post?.page?.id
        };
      })
      .with(BurnForType.Page, () => {
        return {
          pageId: burnForItem.id.toString()
        };
      })
      .otherwise(() => null);

    const burnCommand: BurnQueueCommand = {
      defaultFee: coinInfo[coinBurned].defaultFee,
      burnType,
      burnForType: burnForType,
      burnedBy,
      burnForId: _.toString(burnForId),
      burnValue,
      tipToHashes: tipToHashes,
      amountDana,
      utxos,
      coinBurned: coinBurned,
      extraArguments
    };

    yield put(addBurnQueue(burnCommand));
    yield put(addBurnTransaction(burnCommand));
  } catch (err) {
    const errorMessage = err.message ?? intl.get('post.unableToBurn');
    yield put(
      showToast('error', {
        message: intl.get('toast.error'),
        description: errorMessage
      })
    );
  }
}

function* createTxHexSaga(action: PayloadAction<BurnQueueCommand>) {
  const data = action.payload;
  const walletPaths = yield select(getAllWalletPaths);
  const burnForId = data.burnForId;
  const tipToHashes = data.tipToHashes ? data.tipToHashes : null;
  let txHex, fee;

  try {
    switch (data.coinBurned) {
      case COIN.XPI:
        const xpiContext = yield getContext('useXPI');
        const { createBurnTransaction: createBurnTransactionXPI } = xpiContext();
        const fundingWifXpi = getUtxoWif(data.utxos[0], walletPaths, COIN.XPI);
        const { rawTxHex: rawTxHexXpi, minerFee: minerFeeXpi } = yield createBurnTransactionXPI(
          fundingWifXpi,
          data.utxos,
          data.defaultFee,
          data.burnType,
          data.burnForType,
          data.burnedBy,
          burnForId,
          Number(data.burnValue),
          coinInfo[COIN.XPI].dustSats,
          tipToHashes
        );
        txHex = rawTxHexXpi;
        fee = minerFeeXpi;
        break;
      case COIN.XRG:
        const xrgContext = yield getContext('useXRG');
        const { createBurnTransaction: createBurnTransactionXRG } = xrgContext();
        const fundingWifXrg = getUtxoWif(data.utxos[0], walletPaths, COIN.XRG);
        const { rawTxHex: rawTxHexXrg, minerFee: minerFeeXrg } = yield createBurnTransactionXRG(
          fundingWifXrg,
          data.utxos,
          data.defaultFee,
          data.burnType,
          data.burnForType,
          data.burnedBy,
          burnForId,
          Number(data.burnValue),
          coinInfo[COIN.XRG].dustSats,
          tipToHashes
        );
        txHex = rawTxHexXrg;
        fee = minerFeeXrg;
        break;
    }

    const payload = {
      rawTxHex: txHex,
      minerFee: _.toString(fromSatoshisToCoin(fee))
    };

    yield put({ type: returnTxHex.type, payload });
  } catch (e) {
    yield put(moveAllBurnToFailQueue());
    yield put(clearBurnQueue());
  }
}

function* burnForUpDownVoteSaga(action: PayloadAction<BurnQueueCommand>) {
  let patches, patch: PatchCollection;
  const command = action.payload;

  const { burnForId: postId, extraArguments, amountDana, coinBurned } = command;
  let burnValue = _.toNumber(command.burnValue);
  yield put(createTxHex(command));
  const { payload } = yield take(returnTxHex.type);
  const { rawTxHex: latestTxHex, minerFee } = payload;

  try {
    const dataApi: BurnCommand = {
      txHex: latestTxHex,
      ...command
    };

    const data: Burn = yield call(burnApi.post, dataApi);

    switch (command.burnForType) {
      case BurnForType.Token:
        yield updateTokenBurnValue(action);
        break;
      case BurnForType.Post:
        yield updatePostBurnValue(action);
        break;
      case BurnForType.Comment:
        yield updateCommentBurnValue(action);
        break;
      case BurnForType.Page:
        yield updatePageBurnValue(action);
        break;
      case BurnForType.Account:
        yield updateAccountBurnValue(action);
        break;
      case BurnForType.Worship:
        let promise;
        let createWorshipInput: CreateWorshipInput;
        let data;
        switch (command.worshipType) {
          case WORSHIP_TYPES.PERSON:
            createWorshipInput = {
              worshipedPersonId: command.burnForId,
              worshipedAmount: amountDana
            };
            promise = yield put(
              worshipApi.endpoints.createWorship.initiate({
                input: createWorshipInput
              })
            );
            yield promise;
            data = yield promise.unwrap();
            patches = yield updateWorshipBurnValue(data.createWorship);
            break;
          case WORSHIP_TYPES.TEMPLE:
            createWorshipInput = {
              templeId: command.burnForId,
              worshipedAmount: amountDana
            };
            promise = yield put(
              worshipApi.endpoints.CreateWorshipTemple.initiate({
                input: createWorshipInput
              })
            );
            yield promise;
            data = yield promise.unwrap();
            patches = yield updateWorshipBurnValue(data.createWorshipTemple);
            break;
        }
        break;
    }

    if (_.isNil(data) || _.isNil(data.id)) {
      throw new Error(intl.get('post.unableToBurnForPost'));
    }

    yield put(removeBurnQueue());
    yield put(
      burnForUpDownVoteSuccess(data) &&
        showToast('success', {
          message: intl.get(`toast.success`),
          description: intl.get('burn.totalBurn', {
            burnValue: amountDana,
            totalAmount: burnValue + burnValue * coinInfo[coinBurned].burnFee + Number(minerFee),
            coin: coinInfo[coinBurned].ticker
          })
        })
    );
  } catch (err) {
    console.log(err);
    let message;
    yield put(removeBurnQueue());
    yield put(setTransactionReady());
    if (command.burnForType === BurnForType.Token) {
      message = (err as Error)?.message ?? intl.get('token.unableToBurn');
    } else if (command.burnForType === BurnForType.Post) {
      message = (err as Error)?.message ?? intl.get('post.unableToBurn');
    } else if (command.burnForType === BurnForType.Comment) {
      message = (err as Error)?.message ?? intl.get('comment.unableToBurn');
    } else if (command.burnForType === BurnForType.Worship) {
      message = (err as Error)?.message ?? intl.get('comment.unableToBurn');
      if (patches) {
        yield put(
          worshipApi.util.patchQueryData('allWorshipedByPersonId', { id: command.burnForId }, patches.inversePatches)
        );
      }
    }
    yield put(burnForUpDownVoteFailure(message));
  }
}

function* burnForUpDownVoteSuccessSaga(action: PayloadAction<Burn>) {
  yield put(hideLoading(burnForUpDownVote.type));
}

function* burnForUpDownVoteFailureSaga(action: PayloadAction<string>) {
  yield put(
    showToast('error', {
      message: intl.get('toast.error'),
      description: action.payload,
      duration: 3
    })
  );
  yield put(hideLoading(burnForUpDownVote.type));
}

function* updatePostBurnValue(action: PayloadAction<BurnQueueCommand>) {
  const { extraArguments, burnValue: burnValueAsString, burnType, burnForId, amountDana } = action.payload;
  const { hashtagId, hashtags, minBurnFilter, pageId, query, tokenId, userId, postQueryTags, level } = extraArguments;

  const account = yield select(getSelectedAccount);

  const rootState: LixiStoreStateInterface = yield select();

  // Update timeline
  const timelineInvalidatedBy = yield call(timelineApi.util.selectInvalidatedBy, rootState, ['Timeline']);
  for (const invalidatedBy of timelineInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      timelineApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          const timelineItemToUpdateIndex = draft[field].edges.findIndex(
            item => item.node.id === `${POST_TYPE.POST}:${burnForId}`
          );
          const timelineItemToUpdate = draft[field].edges[timelineItemToUpdateIndex];
          if (timelineItemToUpdateIndex >= 0) {
            let danaBurnUp = timelineItemToUpdate?.node?.data?.dana?.danaBurnUp ?? 0;
            let danaBurnDown = timelineItemToUpdate?.node?.data?.dana?.danaBurnDown ?? 0;
            let danaReceivedUp = timelineItemToUpdate?.node?.data?.dana?.danaReceivedUp ?? 0;
            let danaReceivedDown = timelineItemToUpdate?.node?.data?.dana?.danaReceivedDown ?? 0;
            if (burnType == BurnType.Up) {
              danaBurnUp = danaBurnUp + amountDana;
              danaReceivedUp = danaReceivedUp + amountDana;
            } else {
              danaBurnDown = danaBurnDown + amountDana;
              danaReceivedDown = danaReceivedDown + amountDana;
            }
            const danaBurnScore = danaBurnUp - danaBurnDown;
            const danaReceivedScore = danaReceivedUp - danaReceivedDown;
            draft[field].edges[timelineItemToUpdateIndex].node.data.dana.danaBurnUp = danaBurnUp;
            draft[field].edges[timelineItemToUpdateIndex].node.data.dana.danaBurnDown = danaBurnDown;
            draft[field].edges[timelineItemToUpdateIndex].node.data.dana.danaBurnScore = danaBurnScore;
            draft[field].edges[timelineItemToUpdateIndex].node.data.dana.danaReceivedUp = danaReceivedUp;
            draft[field].edges[timelineItemToUpdateIndex].node.data.dana.danaReceivedDown = danaReceivedDown;
            draft[field].edges[timelineItemToUpdateIndex].node.data.dana.danaReceivedScore = danaReceivedScore;
            if (
              danaReceivedScore < 0 &&
              account?.id !== draft[field].edges[timelineItemToUpdateIndex]?.node?.data?.account?.id
            ) {
              draft[field].edges.splice(timelineItemToUpdateIndex, 1);
              draft[field].totalCount = draft[field].totalCount - 1;
            }
          }
        }
      })
    );
  }

  // Update posts
  const postsInvalidatedBy = yield call(postsApi.util.selectInvalidatedBy, rootState, ['Posts']);
  for (const invalidatedBy of postsInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      postsApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          const postToUpdateIndex = draft[field].edges.findIndex(item => item.node.id === burnForId);
          const postToUpdate = draft[field].edges[postToUpdateIndex];
          if (postToUpdateIndex >= 0) {
            let danaBurnUp = postToUpdate?.node?.dana?.danaBurnUp ?? 0;
            let danaBurnDown = postToUpdate?.node?.dana?.danaBurnDown ?? 0;
            let danaReceivedUp = postToUpdate?.node?.dana?.danaReceivedUp ?? 0;
            let danaReceivedDown = postToUpdate?.node?.dana?.danaReceivedDown ?? 0;
            if (burnType == BurnType.Up) {
              danaBurnUp = danaBurnUp + amountDana;
              danaReceivedUp = danaReceivedUp + amountDana;
            } else {
              danaBurnDown = danaBurnDown + amountDana;
              danaReceivedDown = danaReceivedDown + amountDana;
            }
            const danaBurnScore = danaBurnUp - danaBurnDown;
            const danaReceivedScore = danaReceivedUp - danaReceivedDown;
            draft[field].edges[postToUpdateIndex].node.dana.danaBurnUp = danaBurnUp;
            draft[field].edges[postToUpdateIndex].node.dana.danaBurnDown = danaBurnDown;
            draft[field].edges[postToUpdateIndex].node.dana.danaBurnScore = danaBurnScore;
            draft[field].edges[postToUpdateIndex].node.dana.danaReceivedUp = danaReceivedUp;
            draft[field].edges[postToUpdateIndex].node.dana.danaReceivedDown = danaReceivedDown;
            draft[field].edges[postToUpdateIndex].node.dana.danaReceivedScore = danaReceivedScore;
            if (danaReceivedScore < 0 && account?.id !== draft[field]?.edges[postToUpdateIndex]?.node?.account?.id) {
              draft[field].edges.splice(postToUpdateIndex, 1);
              draft[field].totalCount = draft[field].totalCount - 1;
            }
          }
        }
      })
    );
  }

  // Update single post
  const postInvalidatedBy = yield call(postsApi.util.selectInvalidatedBy, rootState, ['Post']);
  for (const invalidatedBy of postInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      postsApi.util.updateQueryData('Post', originalArgs, draft => {
        let danaBurnUp = draft?.post?.dana?.danaBurnUp ?? 0;
        let danaBurnDown = draft?.post?.dana?.danaBurnDown ?? 0;
        let danaReceivedUp = draft?.post?.dana?.danaReceivedUp ?? 0;
        let danaReceivedDown = draft?.post?.dana?.danaReceivedDown ?? 0;
        if (burnType == BurnType.Up) {
          danaBurnUp = danaBurnUp + amountDana;
          danaReceivedUp = danaReceivedUp + amountDana;
        } else {
          danaBurnDown = danaBurnDown + amountDana;
          danaReceivedDown = danaReceivedDown + amountDana;
        }
        const danaBurnScore = danaBurnUp - danaBurnDown;
        const danaReceivedScore = danaReceivedUp - danaReceivedDown;
        draft.post.dana.danaBurnUp = danaBurnUp;
        draft.post.dana.danaBurnDown = danaBurnDown;
        draft.post.dana.danaBurnScore = danaBurnScore;
        draft.post.dana.danaReceivedUp = danaReceivedUp;
        draft.post.dana.danaReceivedDown = danaReceivedDown;
        draft.post.dana.danaReceivedScore = danaReceivedScore;
      })
    );
  }

  // Update single page
  const pageInvalidatedBy = yield call(pagesApi.util.selectInvalidatedBy, rootState, [{ type: 'Page', id: pageId }]);
  for (const invalidatedBy of pageInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      pagesApi.util.updateQueryData('Page', originalArgs, draft => {
        const { id } = originalArgs;
        if (id !== pageId) return;

        const pageDana = draft?.page?.dana;
        let danaReceivedUp = pageDana?.danaReceivedUp ?? 0;
        let danaReceivedDown = pageDana?.danaReceivedDown ?? 0;
        if (burnType == BurnType.Up) {
          danaReceivedUp = danaReceivedUp + amountDana;
        } else {
          danaReceivedDown = danaReceivedDown + amountDana;
        }
        const danaReceivedScore = danaReceivedUp - danaReceivedDown;
        draft.page.dana.danaReceivedUp = danaReceivedUp;
        draft.page.dana.danaReceivedDown = danaReceivedDown;
        draft.page.dana.danaReceivedScore = danaReceivedScore;
      })
    );
  }

  // Update page timeline
  const pageTimelineInvalidatedBy = yield call(pagesApi.util.selectInvalidatedBy, rootState, ['Pages']);
  for (const invalidatedBy of pageTimelineInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      pagesApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          const pageToUpdateIndex = draft[field]?.edges.findIndex(item => item.node.id === pageId);
          const pageToUpdate = draft[field]?.edges[pageToUpdateIndex];
          if (pageToUpdateIndex >= 0) {
            let danaReceivedUp = pageToUpdate?.node?.dana.danaReceivedUp ?? 0;
            let danaReceivedDown = pageToUpdate?.node?.dana.danaReceivedDown ?? 0;
            if (burnType == BurnType.Up) {
              danaReceivedUp = danaReceivedUp + amountDana;
            } else {
              danaReceivedDown = danaReceivedDown + amountDana;
            }
            const danaReceivedScore = danaReceivedUp - danaReceivedDown;
            draft[field].edges[pageToUpdateIndex].node.dana.danaReceivedUp = danaReceivedUp;
            draft[field].edges[pageToUpdateIndex].node.dana.danaReceivedDown = danaReceivedDown;
            draft[field].edges[pageToUpdateIndex].node.dana.danaReceivedScore = danaReceivedScore;
          }
        }
      })
    );
  }
}

function* updateWorshipBurnValue(data) {
  const { worshipedPerson, temple, id, worshipedAmount } = data;
  const params = {
    orderBy: {
      direction: OrderDirection.Desc,
      field: WorshipOrderField.UpdatedAt
    }
  };
  //At the time being, there are only 2 object to worship, so we use if/else here,
  //In the future, if there is more object to worship, create WorshipType in createWorshipMutation
  if (worshipedPerson) {
    yield put(
      worshipApi.util.updateQueryData('WorshipedPerson', { id: worshipedPerson?.id }, draft => {
        draft.worshipedPerson.totalWorshipAmount = draft.worshipedPerson.totalWorshipAmount + worshipedAmount;
      })
    );
    return yield put(
      worshipApi.util.updateQueryData('allWorshipedByPersonId', { ...params, id: worshipedPerson.id }, draft => {
        draft.allWorshipedByPersonId.edges.unshift({
          cursor: id,
          node: {
            ...data
          }
        });
        draft.allWorshipedByPersonId.totalCount = draft.allWorshipedByPersonId.totalCount + 1;
      })
    );
  } else {
    yield put(
      templeApi.util.updateQueryData('Temple', { id: temple?.id }, draft => {
        draft.temple.totalWorshipAmount = draft.temple.totalWorshipAmount + worshipedAmount;
      })
    );
    return yield put(
      worshipApi.util.updateQueryData('allWorshipedByTempleId', { ...params, id: temple.id }, draft => {
        draft.allWorshipedByTempleId.edges.unshift({
          cursor: id,
          node: {
            ...data
          }
        });
        draft.allWorshipedByTempleId.totalCount = draft.allWorshipedByTempleId.totalCount + 1;
      })
    );
  }
}

function* updatePageBurnValue(action: PayloadAction<BurnQueueCommand>) {
  const { burnValue: burnValueAsString, burnType, burnForId, amountDana } = action.payload;

  const account = yield select(getSelectedAccount);

  const rootState: LixiStoreStateInterface = yield select();
  const pagesInvalidatedBy = yield call(pagesApi.util.selectInvalidatedBy, rootState, ['Pages']);

  for (const invalidatedBy of pagesInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      pagesApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          const pageToUpdateIndex = draft[field].edges.findIndex(item => item.node.id === burnForId);
          const commentToUpdate = draft[field].edges[pageToUpdateIndex];
          if (pageToUpdateIndex >= 0) {
            let danaBurnUp = commentToUpdate?.node?.danaBurnUp ?? 0;
            let danaBurnDown = commentToUpdate?.node?.danaBurnDown ?? 0;
            if (burnType == BurnType.Up) {
              danaBurnUp = danaBurnUp + amountDana;
            } else {
              danaBurnDown = danaBurnDown + amountDana;
            }
            const danaBurnScore = danaBurnUp - danaBurnDown;
            draft[field].edges[pageToUpdateIndex].node.danaBurnUp = danaBurnUp;
            draft[field].edges[pageToUpdateIndex].node.danaBurnDown = danaBurnDown;
            draft[field].edges[pageToUpdateIndex].node.danaBurnScore = danaBurnScore;
            if (danaBurnScore < 0 && account?.id !== draft[field].edges[pageToUpdateIndex]?.node?.commentAccount.id) {
              // Hide the comment with dana < 0
              draft[field].edges.splice(pageToUpdateIndex, 1);
              draft[field].totalCount = draft[field].totalCount - 1;
            }
          }
        }
      })
    );
  }

  //update single page
  const pageInvalidatedBy = yield call(pagesApi.util.selectInvalidatedBy, rootState, ['Page']);
  for (const invalidatedBy of pageInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      pagesApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          let danaReceived = draft[field]?.dana?.danaReceivedScore;

          if (burnType == BurnType.Up) {
            danaReceived = danaReceived + amountDana;
          } else {
            danaReceived = danaReceived - amountDana;
          }

          draft[field].dana.danaReceivedScore! = danaReceived;
        }
      })
    );
  }
}

function* updateAccountBurnValue(action: PayloadAction<BurnQueueCommand>) {
  const { burnValue: burnValueAsString, burnType, burnForId, amountDana, burnedBy } = action.payload;

  const rootState: LixiStoreStateInterface = yield select();
  const pagesInvalidatedBy = yield call(accountApi.util.selectInvalidatedBy, rootState, ['Account']);

  for (const invalidatedBy of pagesInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      pagesApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;

          let danaReceived = 0;
          let danaGiven = amountDana;

          if (burnType == BurnType.Up) {
            danaReceived = amountDana;
          } else {
            danaReceived = -amountDana;
          }

          //received account (not update if self burn)
          if (draft[field].hash160 === burnForId && draft[field].hash160 !== burnedBy) {
            danaReceived += draft[field]?.accountDana?.danaReceived;

            draft[field].accountDana.danaReceived! = danaReceived;
          }

          //givenAccount
          if (draft[field].hash160 === burnedBy) {
            danaGiven += draft[field]?.accountDana?.danaGiven;

            draft[field].accountDana.danaGiven! = danaGiven;
          }
        }
      })
    );
  }
}

function* updateCommentBurnValue(action: PayloadAction<BurnQueueCommand>) {
  const { burnValue: burnValueAsString, burnType, burnForId, amountDana } = action.payload;

  const account = yield select(getSelectedAccount);

  const rootState: LixiStoreStateInterface = yield select();
  const commentsInvalidatedBy = yield call(commentsApi.util.selectInvalidatedBy, rootState, ['Comments']);

  for (const invalidatedBy of commentsInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      commentsApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          const commentToUpdateIndex = draft[field].edges.findIndex(item => item.node.id === burnForId);
          const commentToUpdate = draft[field].edges[commentToUpdateIndex];
          if (commentToUpdateIndex >= 0) {
            let danaBurnUp = commentToUpdate?.node?.danaBurnUp ?? 0;
            let danaBurnDown = commentToUpdate?.node?.danaBurnDown ?? 0;
            if (burnType == BurnType.Up) {
              danaBurnUp = danaBurnUp + amountDana;
            } else {
              danaBurnDown = danaBurnDown + amountDana;
            }
            const danaBurnScore = danaBurnUp - danaBurnDown;
            draft[field].edges[commentToUpdateIndex].node.danaBurnUp = danaBurnUp;
            draft[field].edges[commentToUpdateIndex].node.danaBurnDown = danaBurnDown;
            draft[field].edges[commentToUpdateIndex].node.danaBurnScore = danaBurnScore;
            if (
              danaBurnScore < 0 &&
              account?.id !== draft[field].edges[commentToUpdateIndex]?.node?.commentAccount.id
            ) {
              // Hide the comment with dana < 0
              draft[field].edges.splice(commentToUpdateIndex, 1);
              draft[field].totalCount = draft[field].totalCount - 1;
            }
          }
        }
      })
    );
  }
}

function* updateTokenBurnValue(action: PayloadAction<BurnQueueCommand>) {
  const { burnValue: burnValueAsString, burnType, burnForId, amountDana } = action.payload;
  const id = burnForId;

  const rootState: LixiStoreStateInterface = yield select();
  const tokensInvalidatedBy = yield call(tokenApi.util.selectInvalidatedBy, rootState, ['Tokens']);
  for (const invalidatedBy of tokensInvalidatedBy) {
    const { originalArgs } = invalidatedBy;
    yield put(
      tokenApi.util.updateQueryData('Tokens', originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          const tokenBurnValueIndex = draft[field]?.edges?.findIndex(item => item?.node?.id === id);
          const tokenBurnValue = draft[field]?.edges[tokenBurnValueIndex];
          let danaBurnUp = tokenBurnValue?.node?.dana?.danaBurnUp ?? 0;
          let danaBurnDown = tokenBurnValue?.node?.dana?.danaBurnDown ?? 0;
          if (burnType == BurnType.Up) {
            danaBurnUp = danaBurnUp + amountDana;
          } else {
            danaBurnDown = danaBurnDown + amountDana;
          }
          const danaBurnScore = danaBurnUp - danaBurnDown;
          draft[field].edges[tokenBurnValueIndex].node.dana.danaBurnUp = danaBurnUp;
          draft[field].edges[tokenBurnValueIndex].node.dana.danaBurnDown = danaBurnDown;
          draft[field].edges[tokenBurnValueIndex].node.dana.danaBurnScore = danaBurnScore;
        }
      })
    );
  }
  const tokenInvalidatedBy = yield call(tokenApi.util.selectInvalidatedBy, rootState, [{ type: 'Token', id: id }]);
  for (const invalidatedBy of tokenInvalidatedBy) {
    const { endpointName, originalArgs } = invalidatedBy;
    yield put(
      tokenApi.util.updateQueryData(endpointName, originalArgs, draft => {
        const fields = Object.keys(draft);
        for (const field of fields) {
          if (!draft[field]) continue;
          let danaBurnUp = draft[field].danaBurnUp ?? 0;
          let danaBurnDown = draft[field].danaBurnDown ?? 0;
          if (burnType == BurnType.Up) {
            danaBurnUp = danaBurnUp + amountDana;
          } else {
            danaBurnDown = danaBurnDown + amountDana;
          }
          const danaBurnScore = danaBurnUp - danaBurnDown;
          draft[field].danaBurnUp = danaBurnUp;
          draft[field].danaBurnDown = danaBurnDown;
          draft[field].danaBurnScore = danaBurnScore;
        }
      })
    );
  }
}

function* watchPrepareBurnCommand() {
  yield takeLatest(prepareBurnCommand.type, prepareBurnCommandSaga);
}

function* watchBurnForUpDownVote() {
  yield takeLatest(burnForUpDownVote.type, burnForUpDownVoteSaga);
}

function* watchBurnForUpDownVoteSuccess() {
  yield takeLatest(burnForUpDownVoteSuccess.type, burnForUpDownVoteSuccessSaga);
}

function* watchBurnForUpDownVoteFailure() {
  yield takeLatest(burnForUpDownVoteFailure.type, burnForUpDownVoteFailureSaga);
}

function* watchCreateTxHex() {
  yield takeLatest(createTxHex.type, createTxHexSaga);
}

function* handleRequest(action) {
  try {
    yield put(setTransactionNotReady());
    yield put(burnForUpDownVote(action.payload));
  } catch (err) {
    console.log(err);
    // Dispatch a failure action with the error message
    // yield put({ type: 'USER_FETCH_FAILED', message: err.message });
  }
}

// This saga will create an action channel and use it to dispatch work to one worker saga
function* watchRequests() {
  const requestChan = yield actionChannel(addBurnTransaction, buffers.expanding(10));

  while (true) {
    // Take an action from the channel
    const transactionStatus = yield select(getTransactionStatus);
    const failQueue = yield select(getFailQueue);

    if (failQueue.length > 0) {
      yield flush(requestChan);
    }

    if (transactionStatus) {
      const action = yield take(requestChan);

      yield call(handleRequest, action);
    } else {
      yield take(setTransactionReady.type);
    }
  }
}

export function* burnSaga() {
  if (typeof window === 'undefined') {
    yield all([
      fork(watchCreateTxHex),
      fork(watchBurnForUpDownVote),
      fork(watchBurnForUpDownVoteSuccess),
      fork(watchBurnForUpDownVoteFailure)
    ]);
  } else {
    yield all([
      fork(watchRequests),
      fork(watchCreateTxHex),
      fork(watchBurnForUpDownVote),
      fork(watchBurnForUpDownVoteSuccess),
      fork(watchBurnForUpDownVoteFailure),
      fork(watchPrepareBurnCommand)
    ]);
  }
}
